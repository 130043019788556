
import React, { useState } from 'react'
import { Button } from "src/components/ui/button"
import { Input } from "src/components/ui/input"
import { X } from 'lucide-react'
import Popup from './Popup'
import { axiosInstance } from 'src/lib/axiosInstance'
import { Loader } from './ui/loader'

interface ForgotPasswordPopupProps {
  onClose: () => void;
}

const ForgotPasswordPopup: React.FC<ForgotPasswordPopupProps> = ({ onClose }) => {
  const [email, setEmail] = useState('')
  const [showPopup, setShowPopup] = useState(false)
  const [popupMessage, setPopupMessage] = useState<string | null>(null)
  const [isLoading, setIsLoading] = useState(false)

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault()
    
    if(!email){
      alert("Please add email!")
      return
    }
    setIsLoading(true)

    try {
      const response = await axiosInstance.get(`/reset-password?email=${email}`)
  
      if(response.data.status){
        setPopupMessage(response.data.message)
        setShowPopup(true)
        setIsLoading(false)
      }
      
    } catch (error) {
      setIsLoading(false)
      setPopupMessage((error as any).response.data.message)
      setShowPopup(true)
    }
  }

  const closePopup = () => {
    setShowPopup(false)
    setPopupMessage(null)
  }

  return (
    <div className="fixed inset-0 bg-black bg-opacity-70 flex items-center justify-center z-50">
      <div className="bg-gray-900 p-8 rounded-lg shadow-lg max-w-md w-full relative">
        <button
          onClick={onClose}
          className="absolute top-4 right-4 text-gray-400 hover:text-white"
        >
          <X size={24} />
        </button>
        <h2 className="text-3xl font-bold mb-8 text-white text-center">Forgot Password</h2>
        <form onSubmit={handleSubmit} className="space-y-4">
          <Input
            type="email"
            placeholder="Enter your email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            className="w-full bg-gray-800 text-white border-gray-700"
          />
          <Button 
            type="submit" 
            className="w-full bg-purple-600 hover:bg-purple-700 text-white py-6"
          >
            {isLoading ? 
            <Loader />:
            "Reset Password" 
            }
          </Button>
        </form>
      </div>
      {showPopup && (
        <Popup 
          title="Forgot Password Status" 
          message={popupMessage || ""} 
          onClose={closePopup} 
        />
      )}
    </div>
  )
}

export default ForgotPasswordPopup

