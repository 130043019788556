import LibraryContent from 'src/components/LibraryContent'

export default function LibraryPage() {
  return (
    <div className="min-h-screen bg-gray-900">
      <LibraryContent />
    </div>
  )
}

