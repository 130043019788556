import React, { createContext, useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { getCookieData } from "src/lib/cookies";
import {jwtDecode} from "jwt-decode";

const UserContext = createContext<{ isAuthenticated: boolean }>({
  isAuthenticated: false,
});

export const UserProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(true);
  const navigate = useNavigate();
  const location = useLocation()
  const pathname = location.pathname

  useEffect(() => {
    const authenticate = async () => {
      try {
        const token = await getCookieData();
        
        if (token) {
          const decodedToken: any = jwtDecode(token);
          const expiryTime = decodedToken.exp;
          const currentTime = Math.floor(Date.now() / 1000);

          if (expiryTime && expiryTime > currentTime) {
            setIsAuthenticated(true);
            return;
          }
        }else{
            setIsAuthenticated(false)
            return
        }
        throw new Error("Invalid token");
      } catch {
        if(pathname == "/trivia" || pathname == "/claim-nft" || pathname == "/dashboard"  ){
           navigate("/login"); 
        }

        setIsAuthenticated(false)
       
      }
    };

    authenticate();
  }, []);

  return (
    <UserContext.Provider value={{ isAuthenticated }}>
      {children}
    </UserContext.Provider>
  );
};

export const useUser = () => useContext(UserContext);
