import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "src/components/ui/button";
import { Input } from "src/components/ui/input";
import { FaGoogle, FaFacebook, FaEthereum } from "react-icons/fa";
import { axiosInstance } from "src/lib/axiosInstance";
import Cookies from "js-cookie";
import { signature, signMessage } from "src/lib/signature.utils";
import { setCookieData } from "src/lib/cookies";
import { Loader } from "src/components/ui/loader";
import { X } from "lucide-react";
import Popup from "src/components/Popup";
import { useAddress, useSigner } from "@thirdweb-dev/react";
import ConnectWalletButton from "src/connect-wallet/ConnectWalletButton";

export default function RegisterPage() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [popupMessage, setPopupMessage] = useState<string | null>(null);
  const [showPopup, setShowPopup] = useState(false);
  const [currentSigner, setCurrentSigner] = useState<any>();
  const [currentAddress, setCurrentAddress] = useState("");
  const address = useAddress();
  const signer = useSigner();
  const navigate = useNavigate();

  useEffect(() => {
    if (signer) {
      setCurrentSigner(signer);
    }
  }, [signer]);

  useEffect(() => {
    if (address) {
      setCurrentAddress(address);
    }
  }, [address]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    setPasswordError("");
    setConfirmPasswordError("");
    setIsLoading(true);

    let valid = true;

    const passwordRegex =
      /^(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{8,}$/;

    if (!passwordRegex.test(password)) {
      setPasswordError(
        "Password must be at least 8 characters long, include one uppercase letter, one number, and one special character."
      );
      valid = false;
    }

    if (password !== confirmPassword) {
      setConfirmPasswordError("Passwords do not match.");
      valid = false;
    }

    if (valid) {
      try {
        const data = {
          name: name,
          email: email,
          password: password,
          confirmPassword: confirmPassword,
        };

        const response = await axiosInstance.post("/user-register", data);

        if (response.data.status) {
          await setCookieData(response.data?.token);
          window.location.reload();
        } else {
          alert(response.data.message);
        }
      } catch (error) {
        alert((error as any).response.data.message);
      }
    }

    setIsLoading(false);
  };

  const googleSSO = async () => {
    window.location.href = `${process.env.REACT_APP_BASE_URL_SERVER}/auth/google`;
  };

  const faceBookSSO = async () => {
    window.location.href = `${process.env.REACT_APP_BASE_URL_SERVER}/auth/facebook`;
  };

  const handleWeb3Login = async () => {
    if (currentAddress && currentSigner) {
      try {
        const message = await signMessage(currentAddress);

        const sign = await currentSigner?.signMessage(message);

        const data = { address: currentAddress, signature: sign };

        const response = await axiosInstance.post("/auth/wallet", data);

        if (response.data.status) {
          setCookieData(response.data?.token);
          window.location.reload();
        }
      } catch (error) {
        setPopupMessage((error as any).message);
        setShowPopup(true);
      }
    } else {
      setPopupMessage("Please connect your wallet and then try");
      setShowPopup(true);
    }
  };

  const closePopup = () => {
    setShowPopup(false);
    setPopupMessage(null);
  };

  useEffect(() => {
    if (Cookies.get("token")) {
      navigate("/");
    }
  });

  return (
    <div
      className="min-h-screen bg-gray-900 flex items-center justify-center px-4 mt-4 sm:px-6"
      style={{ paddingTop: "80px" }}
    >
      <div className="relative w-full max-w-md bg-[#0f1116]/50 rounded-lg shadow-xl max-h-[90vh] overflow-y-auto border border-gray-700">
        <div className="sticky top-0 bg-gray-800 p-4 rounded-t-lg flex justify-between items-center z-10">
          <h2 className="text-xl font-bold text-white">Register to MetaWorm</h2>
          <button
            onClick={() => navigate("/")}
            className="text-gray-300 hover:text-white transition-colors focus:outline-none focus:ring-2 focus:ring-gray-400 rounded-full p-1"
            aria-label="Close"
          >
            <X className="h-6 w-6" />
          </button>
        </div>

        <div className="p-4">
          <form onSubmit={handleSubmit} className="space-y-4">
            <div className="space-y-2">
              <Input
                type="text"
                placeholder="Name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                required
                className="w-full bg-gray-800/50 border-gray-700 text-white placeholder:text-gray-400 h-12"
              />
            </div>
            <div className="space-y-2">
              <Input
                type="email"
                placeholder="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
                className="w-full bg-gray-800/50 border-gray-700 text-white placeholder:text-gray-400 h-12"
              />
            </div>
            <div className="space-y-2">
              <Input
                type="password"
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                onInput={(e) => {
                  const value = (e.target as HTMLInputElement).value;
                  const passwordRegex =
                    /^(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{8,}$/;
                  if (!passwordRegex.test(value)) {
                    setPasswordError(
                      "Password must be at least 8 characters long, include one uppercase letter, one number, and one special character."
                    );
                  } else {
                    setPasswordError(""); // Clear error if criteria are met
                  }
                }}
                required
                className="w-full bg-gray-800/50 border-gray-700 text-white placeholder:text-gray-400 h-12"
              />
              {passwordError && (
                <p className="text-red-500 text-sm">{passwordError}</p>
              )}
            </div>
            <div className="space-y-2">
              <Input
                type="password"
                placeholder="Confirm Password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                onInput={(e) => {
                  const value = (e.target as HTMLInputElement).value;
                  if (value !== password) {
                    setConfirmPasswordError("Passwords do not match.");
                  } else {
                    setConfirmPasswordError(""); // Clear error if passwords match
                  }
                }}
                required
                className="w-full bg-gray-800/50 border-gray-700 text-white placeholder:text-gray-400 h-12"
              />
              {confirmPasswordError && (
                <p className="text-red-500 text-sm">{confirmPasswordError}</p>
              )}
            </div>

            <Button
              type="submit"
              className="w-full bg-purple-600 hover:bg-purple-700 text-white py-6"
              disabled={isLoading}
            >
              {isLoading ? <Loader /> : "Register"}
            </Button>
          </form>
        </div>
        <div className="mt-2 text-center">
          <button
            onClick={() => navigate("/login")}
            className="text-purple-400 hover:text-purple-300 text-base transition-colors"
          >
            Have an account? Login!
          </button>
        </div>

        <div className="mt-4 space-y-4">
          <div className="mt-4 flex items-center">
            <hr className="flex-grow border-gray-600" />
            <span className="px-4 text-gray-400">or</span>
            <hr className="flex-grow border-gray-600" />
          </div>

          <div className="mt-4 space-y-2 p-4">
            <Button
              onClick={() => googleSSO()}
              className="w-full bg-red-600 hover:bg-red-700 text-white h-12 text-base font-semibold"
            >
              <FaGoogle className="mr-2" /> Sign up with Google
            </Button>
            <Button
              onClick={() => faceBookSSO}
              className="w-full bg-blue-600 hover:bg-blue-700 text-white h-12 text-base font-semibold"
            >
              <FaFacebook className="mr-2" /> Sign up with Facebook
            </Button>
            {!address ? (
              <ConnectWalletButton
                claim={false}
                login={false}
                signup={true}
                registerWallet={false}
                handleWeb3Login={handleWeb3Login}
                handleRegister={null}
                handleClaim={null}
              />
            ) : (
              <Button
                onClick={() => handleWeb3Login()}
                className="w-full bg-orange-500 hover:bg-orange-600 text-white h-12 text-base font-semibold"
              >
                <FaEthereum className="mr-2 h-5 w-5" /> Login with Wallet
              </Button>
            )}
          </div>
        </div>
      </div>
      {showPopup && (
        <Popup
          title="Register Status"
          message={popupMessage || ""}
          onClose={closePopup}
        />
      )}
    </div>
  );
}
