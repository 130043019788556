import Header from "src/components/Header";
import React, { ReactNode, useEffect, useState } from "react";
import { GlobalDataProvider } from "src/context/GlobalDataContext";
import { axiosInstance } from "src/lib/axiosInstance";
import { getCookieData } from "src/lib/cookies";
import { Loader } from "src/components/ui/loader";
import {
  ThirdwebProvider,
  metamaskWallet,
  trustWallet,
  walletConnect,
  ThirdwebSDKProvider,
  coinbaseWallet,
} from "@thirdweb-dev/react";
import { Zksync, ZksyncSepoliaTestnet } from "@thirdweb-dev/chains";

const Layout = ({ children }: { children: ReactNode }) => {
  const [userProfile, setUserProfile] = useState<any>(null);
  const clientId = process.env.REACT_APP_THIRDWEB_CLIENT_ID;
  let activeChain;

  if (process.env.REACT_APP_CHAIN_ENV === "mainnet") {
    activeChain = Zksync;
  } else {
    activeChain = ZksyncSepoliaTestnet;
  }

  useEffect(() => {
    const fetchUserProfile = async () => {
      try {
        const token = await getCookieData();

        if (!token) {
          setUserProfile(false);
          return;
        }

        const [profileResponse, nftsResponse] = await Promise.all([
          axiosInstance.get("/profile", {
            headers: { Authorization: `Bearer ${token}` },
          }),
          axiosInstance.get("/user-nfts", {
            headers: { Authorization: `Bearer ${token}` },
          }),
        ]);

        let totalNFTs = nftsResponse.data?.status
          ? nftsResponse.data?.totalNFTs
          : 0;

        if (profileResponse.data.status) {
          setUserProfile({
            nfts: totalNFTs,
            userProfile: profileResponse.data.userProfile,
          });
        } else {
          setUserProfile(profileResponse.data);
        }
      } catch (error) {
        setUserProfile(false);

        console.log("Error fetching user profile:", error);
      }
    };

    fetchUserProfile();
  }, []);

  if (userProfile === null) {
    return (
      <div className="min-h-screen bg-[#1a1f2e] p-6 flex items-center justify-center relative">
        {" "}
        <Loader />
      </div>
    );
  }

  return (
    <ThirdwebSDKProvider
      activeChain={activeChain}
      supportedChains={[activeChain]}
      clientId={clientId}
      
    >
      <ThirdwebProvider
        activeChain={activeChain}
        clientId={clientId}
        autoSwitch={true}
        supportedWallets={[
          metamaskWallet({ recommended: true }),
          coinbaseWallet(),
          walletConnect(),
          trustWallet(),
        ]}
        
      >
        <GlobalDataProvider globalData={userProfile}>
          <div>
            <Header />
            <main>{children}</main>
          </div>
        </GlobalDataProvider>
      </ThirdwebProvider>
    </ThirdwebSDKProvider>
  );
};

export default Layout;
