import React, { useEffect, useState, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Button } from "src/components/ui/button";
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from "src/components/ui/card";
import { Coins, X } from "lucide-react";
import { axiosInstance } from "src/lib/axiosInstance";
import { useGlobalData } from "src/context/GlobalDataContext";
import { Loader } from "src/components/ui/loader";
import { getCookieData } from "src/lib/cookies";
import Popup from "src/components/Popup";
import { FaArrowLeft } from "react-icons/fa";

export default function TriviaPage() {
  const navigate = useNavigate();
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [question, setQuestion] = useState<any[]>([]);
  const [score, setScore] = useState(0);
  const [showResult, setShowResult] = useState(false);
  const [quizCompleted, setQuizCompleted] = useState(false);
  const [cooldownTime, setCooldownTime] = useState<number | null>(null);
  const [bonusQuestion, setBonusQuestion] = useState<any>(null);
  const [profileData, setProfileData] = useState<any>(null);
  const [loader, setLoader] = useState(true);
  const [clickedOptionIndex, setClickedOptionIndex] = useState<number | null>(
    null
  );
  const globalData: any = useGlobalData();
  const [showPopup, setShowPopup] = useState(false);
  const [popupMessage, setPopupMessage] = useState<string | null>(null);

  const globalDataLoaded = useRef(false);

  const COOLDOWN_PERIOD = 3600 * 24;
  const BONUS_SCORE = 300;

  const [selectedAnswer, setSelectedAnswer] = useState<string | null>(null);
  const [showNextButton, setShowNextButton] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState<boolean | null>(null);
  const [isInitialized, setIsInitialized] = useState(false);

  useEffect(() => {
    const savedState = JSON.parse(localStorage.getItem('quizState') || '{}');
    if (savedState && savedState.currentQuestion !== undefined) {
      setCurrentQuestion(savedState.currentQuestion);
      setQuestion(savedState.question || []);
      setScore(savedState.score || 0);
      setBonusQuestion(savedState.bonusQuestion || null);
    }
    setIsInitialized(true);
  }, []);

  useEffect(() => {
    if(isInitialized){
      localStorage.setItem(
        "quizState",
        JSON.stringify({
          currentQuestion,
          question,
          score,
          bonusQuestion,
        })
      );
    }
    
  }, [question, currentQuestion, score, bonusQuestion, isInitialized]);

  const handleAnswer = async (selectedAnswer: string, index: number) => {
    setClickedOptionIndex(index);
    setSelectedAnswer(selectedAnswer);

    const resp = await submitAnswer(selectedAnswer);

    if (resp.status) {
      setSelectedStatus(resp.status);
      if (resp.status) {
        setScore((prevScore) => prevScore + 100);
      }
    } else {
      alert(resp?.documentInfo.source);
    }
    setShowNextButton(true);
  };

  const handleNextQuestion = () => {
    setSelectedStatus(null);
    setShowNextButton(false);
    setClickedOptionIndex(null);

    if (bonusQuestion) {
      completeBonusQuestion(selectedAnswer!);
      return;
    }

    if (question.length && currentQuestion + 1 < question.length) {
      setCurrentQuestion(currentQuestion + 1);
    } else if (score === BONUS_SCORE) {
      triggerBonusQuestion();
    } else {
      finishQuiz();
    }
  };

  useEffect(() => {
    if (!globalData?.userProfile?.NFT_claim_status) {
      navigate("/claim-nft");
      return;
    }

    if (!globalDataLoaded.current) {
      setLoader(true);

      getData().then(() => {
        setLoader(false);
        globalDataLoaded.current = true;
      });

      if (!question.length) {
        getQuestion().then(() => setLoader(false));
      }
    }
  }, [globalData]);

  const getData = async () => {
    try {
      const response = await axiosInstance.get("/profile", {
        headers: { Authorization: `Bearer ${await getCookieData()}` },
      });

      if (response.data?.status) {
        setProfileData(response.data.userProfile);

        if (response.data.userProfile.last_quiz_attempt) {
          const now = new Date().getTime();
          const lastAttemptTime = new Date(
            response.data.userProfile.last_quiz_attempt
          ).getTime();
          const timeSinceLastAttempt = now - lastAttemptTime;
          const remainingTime = 24 * 60 * 60 * 1000 - timeSinceLastAttempt;
          const remainingTimeInSeconds = Math.floor(remainingTime / 1000);

          if (remainingTimeInSeconds > 0) {
            const quizData = JSON.parse(localStorage.getItem("quizState") || "{}");

            if (quizData?.question.length == 0) {
              setCooldownTime(remainingTimeInSeconds);
            }
          } else {
            setCooldownTime(null);
          }
        }
      } else {
        setProfileData({});
      }
    } catch (error) {
      console.error("Error fetching profile data:", error);
    }
  };

  const getQuestion = async () => {
    try {
      const response = await axiosInstance.get("/trivia", {
        headers: { Authorization: `Bearer ${await getCookieData()}` },
      });
      setQuestion(response.data);
    } catch (error) {
      console.error("Error fetching trivia questions:", error);
    }
  };

  const submitAnswer = async (selectedAnswer: string, isBonus = false) => {
    try {
      const data = {
        questionId: isBonus ? bonusQuestion.id : question[currentQuestion].id,
        answer: selectedAnswer,
        isBonus,
        isFinal: isBonus || currentQuestion === 2,
      };
      const response = await axiosInstance.post("/trivia", data, {
        headers: { Authorization: `Bearer ${await getCookieData()}` },
      });

      return response.data;
    } catch (error) {
      alert((error as any).response.data.message);
      return false;
    }
  };

  const triggerBonusQuestion = async () => {
    try {
      setLoader(true);
      const response = await axiosInstance.get("/trivia-bonus", {
        headers: { Authorization: `Bearer ${await getCookieData()}` },
      });
      setBonusQuestion(response.data);
      setLoader(false);
    } catch (error) {
      console.error("Failed to fetch bonus question", error);
      finishQuiz();
    }
  };

  const completeBonusQuestion = async (selectedAnswer: string) => {
    const resp = await submitAnswer(selectedAnswer, true);
    if (resp && resp.status) {
      setPopupMessage(
        "You earned a wearable NFT, you can claim it later, for answering 4 correct questions!!"
      );
      setShowPopup(true);
    }
    finishQuiz();
  };

  const finishQuiz = () => {
    setShowResult(true);
    setQuizCompleted(true);
    setCooldownTime(COOLDOWN_PERIOD);
    localStorage.removeItem("quizState")
  };

  const closePopup = () => {
    setShowPopup(false);
    setPopupMessage(null);
  };
  const resetQuiz = () => {
    setCurrentQuestion(0);
    setScore(0);
    setShowResult(false);
    setQuizCompleted(false);
    setBonusQuestion(null);
    getQuestion();
  };

  return (
    <div
      className="min-h-screen bg-[#1a1f2e] p-6 flex items-center justify-center relative flex flex-col"
      style={{ paddingTop: "80px" }}
    >
      <Card className="max-w-2xl mx-auto bg-[#1e2538] border-gray-800">
        <CardHeader className="flex justify-between items-center">
          <div style={{ alignSelf: "baseline" }}>
            <Link
              to="/"
              className="flex items-center text-gray-400 hover:text-white transition-colors duration-200"
            >
              <FaArrowLeft className="mr-2" />
              <span>Back to Home</span>
            </Link>
          </div>
          <CardTitle className="text-2xl text-white">
            {!showResult
              ? `Question ${currentQuestion + 1} of ${question?.length}`
              : "Quiz Complete"}
          </CardTitle>
          <div className="flex items-center bg-[#141824] px-3 py-1 rounded-full">
            <Coins className="h-5 w-5 text-yellow-500 mr-2" />
            <span className="text-white font-bold">{score} Tokens</span>
          </div>
        </CardHeader>
        <CardContent className="space-y-6">
          {loader ? (
            <div className="flex justify-center items-center">
              <Loader />
            </div>
          ) : (
            <>
              {cooldownTime ? (
                <div className="text-center text-white">
                  You just attempted the quiz. Please come back in{" "}
                  <span className="font-bold">{cooldownTime}s</span>.
                </div>
              ) : showResult ? (
                <div className="text-center space-y-4">
                  <h2 className="text-2xl text-white font-bold">
                    Quiz Completed!
                  </h2>
                  <p className="text-xl text-white">
                    Your score: {score} out of {question?.length}
                  </p>
                  {quizCompleted && score === BONUS_SCORE && !bonusQuestion && (
                    <p className="text-lg text-white">
                      You’ve unlocked a bonus question!
                    </p>
                  )}
                  <Button
                    onClick={resetQuiz}
                    className="bg-blue-600 hover:bg-blue-700 text-white mt-4"
                  >
                    Play Again
                  </Button>
                </div>
              ) : (
                <>
                  <h2 className="text-2xl text-white font-bold">
                    {bonusQuestion
                      ? bonusQuestion.question
                      : question?.[currentQuestion]?.question}
                  </h2>
                  <div className="grid grid-cols-1 gap-4">
                    {(bonusQuestion
                      ? bonusQuestion.options
                      : question?.[currentQuestion]?.options
                    )?.map((option: any, index: number) => (
                      <Button
                        key={index}
                        style={{
                          display: "block",
                          whiteSpace: "normal",
                          minHeight: "fit-content",
                        }}
                        className={`text-white ${
                          clickedOptionIndex === index
                            ? selectedStatus === true
                              ? "bg-green-500" // Correct answer
                              : "bg-red-500" // Incorrect answer
                            : "bg-gray-800" // Default state
                        }`}
                        onClick={() => handleAnswer(option, index)}
                        disabled={clickedOptionIndex !== null || showNextButton}
                      >
                        {clickedOptionIndex === index && !showNextButton ? (
                          <Loader />
                        ) : (
                          option
                        )}
                      </Button>
                    ))}
                  </div>
                  {showNextButton && (
                    <Button
                      onClick={handleNextQuestion}
                      className="bg-blue-600 hover:bg-blue-700 text-white mt-4"
                    >
                      Next
                    </Button>
                  )}
                </>
              )}
            </>
          )}
        </CardContent>
      </Card>
      {showPopup && (
        <Popup
          title="Congrats!!"
          message={popupMessage || ""}
          onClose={closePopup}
        />
      )}
    </div>
  );
}
