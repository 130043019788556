import VideoBackground from "src/components/VideoBackground";
import { FaArrowLeft } from "react-icons/fa";
import { Button } from "src/components/ui/button";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";

export default function TermsConditions() {
  const navigate = useNavigate()

  useEffect(() => {
    window.scrollTo(0, 0)
    }, [])

  return (
    <div className="min-h-screen bg-gray-900 flex items-center justify-center px-4 sm:px-6 m-auto">
      <VideoBackground />
      <div className="container mx-auto px-4 py-8 pt-24">
        <div className="max-w-4xl m-auto space-y-8">
          <div className="text-center" style={{position: "relative"}}>
            <h1 className="bg-gradient-to-r from-purple-400 to-pink-600 bg-clip-text text-4xl font-bold text-transparent sm:text-5xl">
              Terms of Service
            </h1>
            <p className="mt-8 text-gray-400">
              Effective Date: January 01, 2024
            </p>
          </div>

          <div className="space-y-6 rounded-lg border border-purple-500/20 bg-black/50 p-6 backdrop-blur-xl">
            <section className="space-y-4">
              <p className="text-gray-300">
                Welcome to MetaWorm.io. These Terms of Service ("Terms") govern
                your use of our website and services (collectively, "Services").
                By accessing or using our Services, you agree to be bound by
                these Terms. If you do not agree to these Terms, please do not
                use our Services.
              </p>
            </section>

            <section className="space-y-4">
              <h2 className="text-2xl font-semibold text-purple-400">
                1. Acceptance of Terms
              </h2>
              <p className="text-gray-300">
                By accessing or using our Services, you agree to comply with and
                be bound by these Terms and our Privacy Policy. These Terms
                apply to all visitors, users, and others who access or use the
                Services.
              </p>
            </section>

            <section className="space-y-4">
              <h2 className="text-2xl font-semibold text-purple-400">
                2. Eligibility
              </h2>
              <p className="text-gray-300">
                You must be at least 13 years old to use our Services. By using
                our Services, you represent and warrant that you are at least 13
                years old and have the legal capacity to enter into these Terms.
              </p>
            </section>

            <section className="space-y-4">
              <h2 className="text-2xl font-semibold text-purple-400">
                3. Account Registration
              </h2>
              <p className="text-gray-300">
                To access certain features of our Services, you may be required
                to register for an account. You agree to:
              </p>
              <ul className="ml-6 list-disc space-y-2 text-gray-300">
                <li>
                  Provide accurate, current, and complete information during the
                  registration process.
                </li>
                <li>
                  Update such information to keep it accurate, current, and
                  complete.
                </li>
              </ul>
              <p className="text-gray-300">
                You are responsible for maintaining the confidentiality of your
                account password and for all activities that occur under your
                account.
              </p>
            </section>

            <section className="space-y-4">
              <h2 className="text-2xl font-semibold text-purple-400">
                4. Use of Services
              </h2>
              <p className="text-gray-300">
                You agree to use our Services only for lawful purposes and in
                accordance with these Terms. You agree not to use our Services:
              </p>
              <ul className="ml-6 list-disc space-y-2 text-gray-300">
                <li>
                  In violation of any applicable federal, state, local, or
                  international law or regulation.
                </li>
                <li>
                  To exploit, harm, or attempt to exploit or harm minors in any
                  way.
                </li>
                <li>
                  To transmit unsolicited promotional material, including "junk
                  mail," "chain letters," or "spam."
                </li>
                <li>
                  To impersonate MetaWorm, its employees, other users, or any
                  other entity.
                </li>
                <li>
                  To engage in conduct that restricts or inhibits anyone's use
                  or enjoyment of the Services, or that may harm MetaWorm or its
                  users.
                </li>
              </ul>
            </section>

            <section className="space-y-4">
              <h2 className="text-2xl font-semibold text-purple-400">
                5. Intellectual Property Rights
              </h2>
              <p className="text-gray-300">
                The Services and their contents, features, and
                functionality—including but not limited to all information,
                software, text, displays, images, video, and audio—are owned by
                MetaWorm, its licensors, or other providers and are protected by
                copyright, trademark, and other intellectual property laws.
              </p>
            </section>

            <section className="space-y-4">
              <h2 className="text-2xl font-semibold text-purple-400">
                6. User Content
              </h2>
              <p className="text-gray-300">
                You may Post content on or through the Services. By Posting User
                Content, you:
              </p>
              <ul className="ml-6 list-disc space-y-2 text-gray-300">
                <li>Retain ownership of your intellectual property rights.</li>
                <li>
                  Grant MetaWorm a worldwide, non-exclusive, royalty-free,
                  sublicensable, and transferable license to use, reproduce,
                  distribute, and display your User Content in connection with
                  the Services and MetaWorm's business.
                </li>
              </ul>
            </section>

            <section className="space-y-4">
              <h2 className="text-2xl font-semibold text-purple-400">
                7. Termination
              </h2>
              <p className="text-gray-300">
                We may terminate or suspend your access to all or part of the
                Services immediately, without prior notice, for any reason,
                including violation of these Terms. Upon termination, your right
                to use the Services ceases immediately.
              </p>
            </section>

            <section className="space-y-4">
              <h2 className="text-2xl font-semibold text-purple-400">
                8. Limitation of Liability
              </h2>
              <p className="text-gray-300">
                MetaWorm, its affiliates, and service providers will not be
                liable for any damages arising from your use or inability to use
                the Services. This includes, but is not limited to, direct,
                indirect, incidental, consequential, or punitive damages.
              </p>
            </section>

            <section className="space-y-4">
              <h2 className="text-2xl font-semibold text-purple-400">
                9. Indemnification
              </h2>
              <p className="text-gray-300">
                You agree to indemnify MetaWorm and its affiliates, licensors,
                and service providers against claims, liabilities, damages, and
                expenses arising from your use of the Services or violation of
                these Terms.
              </p>
            </section>

            <section className="space-y-4">
              <h2 className="text-2xl font-semibold text-purple-400">
                10. Governing Law and Dispute Resolution
              </h2>
              <p className="text-gray-300">
                These Terms are governed by the laws of the State of Delaware.
                Any disputes shall be resolved exclusively in the state or
                federal courts located in Delaware.
              </p>
            </section>

            <section className="space-y-4">
              <h2 className="text-2xl font-semibold text-purple-400">
                11. Changes to Terms
              </h2>
              <p className="text-gray-300">
                MetaWorm may update these Terms at its sole discretion. Changes
                are effective immediately upon posting and apply to all use of
                the Services thereafter. Continued use of the Services indicates
                acceptance of the revised Terms.
              </p>
            </section>
            <div className="flex items-center justify-center">
              <Button
                onClick={() => navigate("/")}
                className="flex items-center text-purple-400 hover:text-purple-300 mb-6"
              >
                <FaArrowLeft className="mr-2" /> Back to Home
              </Button>
            </div>
          </div>
        </div>
      </div>

    </div>
  );
}
