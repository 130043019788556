import React, { useEffect } from "react";
import { ConnectWallet, useAddress, useSigner } from "@thirdweb-dev/react";

interface ConnectWalletProps {
  login: boolean;
  signup: boolean;
  registerWallet: boolean;
  claim: boolean;
  handleWeb3Login: (() => void) | null;
  handleRegister: (() => void) | null;
  handleClaim: (()=> void) | null;
}

const ConnectWalletButton: React.FC<ConnectWalletProps> = ({
  signup,
  registerWallet,
  login,
  claim,
  handleWeb3Login,
  handleRegister,
  handleClaim
}) => {
  const address = useAddress();
  const signer = useSigner();

  
  const handleButtonClick = () => {
    try {

      if(claim && handleClaim !==null){        
        handleClaim()
      }
      
      if ((signup || login) && handleWeb3Login !== null) {
        handleWeb3Login();
      }

      if (registerWallet && handleRegister !== null) {
        handleRegister();
      }
    } catch (error) {}
  };

  useEffect(() => {}, [address, signer]);

  return (
    <ConnectWallet
      className={
        address
          ? "w-full bg-orange-500 hover:bg-orange-600 text-white h-12 text-base font-semibold"
          : ""
      }
      style={{
        width: login || signup ? "-webkit-fill-available" : "fit-content",
        backgroundColor: login || signup ? "#F97308" : "",
        color: login || signup ? "white" : "black",
        borderRadius: login || signup ? "0" : "12px",
      }}
      switchToActiveChain={true}
      theme="dark"
      btnTitle={
        signup
          ? "Signup with wallet"
          : login
          ? "Login with Wallet"
          : "Connect Wallet"
      }
      onConnect={handleButtonClick}
    />
  );
};

export default ConnectWalletButton;
