import React, { useState, useEffect } from "react";
import { Button } from "src/components/ui/button";

interface PopupProps {
  title: string;
  message: string;
  onSubmit: () => void;
  onClose: () => void
}

const EmailVerificationPopup: React.FC<PopupProps> = ({
  title,
  message,
  onSubmit,
  onClose
}) => {
  const [isResendDisabled, setIsResendDisabled] = useState(false);
  const [timer, setTimer] = useState(30);

  const handleSend = () => {
    if (!isResendDisabled) {
      onSubmit();
      setIsResendDisabled(true);
      setTimer(30);
    }
  };

  useEffect(() => {
    let interval: NodeJS.Timeout;

    if (isResendDisabled) {
      interval = setInterval(() => {
        setTimer((prevTimer) => {
          if (prevTimer <= 1) {
            clearInterval(interval);
            setIsResendDisabled(false);
            return 0;
          }
          return prevTimer - 1;
        });
      }, 1000);
    }

    return () => clearInterval(interval);
  }, [isResendDisabled]);

  return (
    <div className="fixed inset-0 bg-gray-800 bg-opacity-75 flex items-center justify-center z-50">
      <div className="bg-white text-black p-8 rounded-lg w-3/4 max-w-md">
        <h2 className="text-xl font-semibold mb-4">{title}</h2>
        <p>{message}</p>
        <div className="mt-4 text-center">
          <Button
            onClick={handleSend}
            disabled={isResendDisabled}
            className="w-full bg-purple-600 hover:bg-purple-700 text-white mb-4"
          >
            {isResendDisabled ? `Resend Link (${timer}s)` : "Send Verify Link"}
          </Button>

          <Button
            onClick={onClose}
            disabled={isResendDisabled}
            className="w-full bg-purple-600 hover:bg-purple-700 text-white mb-4"
          >
            Close
          </Button>
        </div>
      </div>
    </div>
  );
};

export default EmailVerificationPopup;
