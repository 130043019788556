import { privacyPolicy } from "src/privacy";
import VideoBackground from "src/components/VideoBackground";
import { FaArrowLeft } from "react-icons/fa";
import { Button } from "src/components/ui/button";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";

export default function PrivacyPolicy() {
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const policy = {
    title: "1. Information We Collect",
    subSections: [
      {
        subTitle: "a. Personal Data",
        list: [
          "Name",
          "Email address",
          "Wallet address",
          "Social media profile (if you use social login)",
        ],
      },
      {
        subTitle: "b. Non-Personal Data",
        list: [
          "Browser type and version",
          "Operating system",
          "IP address",
          "Device identifiers",
          "Log data (time of access, pages visited)",
        ],
      },
      {
        subTitle: "c. Cookies and Tracking",
        paragraphs: [
          "We use cookies and similar tracking technologies to enhance user experience. You can control cookie settings in your browser. For more details, see our Cookie Policy.",
        ],
      },
    ],
  };

  return (
    <div className="min-h-screen bg-gray-900 flex items-center justify-center px-4 sm:px-6 m-auto">
      <VideoBackground />
      <div className="container m-auto px-4 py-8 pt-24">
        <div className="max-w-4xl mx-auto space-y-8">
          <div className="text-center" style={{ position: "relative" }}>
            <h1 className="bg-gradient-to-r from-purple-400 to-pink-600 bg-clip-text text-4xl font-bold text-transparent sm:text-5xl leading-tight">
              Privacy Policy
            </h1>
            <p className="mt-8 text-gray-400">
              Effective Date: January 01, 2024
            </p>
          </div>

          <div className="space-y-6 rounded-lg border border-purple-500/20 bg-black/50 p-6 backdrop-blur-xl">
            <section className="space-y-4">
              <p className="text-gray-300">
                MetaWorm, Inc. (“we,” “us,” or “our”) is committed to protecting
                your privacy. This Privacy Policy explains how we collect, use,
                disclose, and safeguard your information when you visit our
                website MetaWorm.io, including any other media form, media
                channel, mobile website, or mobile application related or
                connected thereto (collectively, the “Site”). Please read this
                Privacy Policy carefully. By using the Site, you agree to the
                terms of this policy. If you do not agree with the terms, please
                do not use the Site.
              </p>
            </section>
            <section className="space-y-4">
              <h2 className="text-2xl font-semibold text-purple-400">
                {policy.title}
              </h2>
              {policy.subSections?.map((paragraph, i) => (
                <>
                  <p key={i} className="text-gray-300">
                    {paragraph.subTitle}
                  </p>

                  {paragraph?.list && (
                    <ul className="ml-6 list-disc space-y-2 text-gray-300">
                      {paragraph.list.map((item, i) => (
                        <li key={i}>{item}</li>
                      ))}
                    </ul>
                  )}
                  {paragraph?.paragraphs && (
                    <ul className="ml-6 list-disc space-y-2 text-gray-300">
                      {paragraph.paragraphs.map((item, i) => (
                        <p key={i} className="text-gray-300">
                          {item}
                        </p>
                      ))}
                    </ul>
                  )}
                </>
              ))}
            </section>

            {privacyPolicy.map((section, index) => (
              <section key={index} className="space-y-4">
                <h2 className="text-2xl font-semibold text-purple-400">
                  {section.title}
                </h2>
                {section.paragraphs?.map((paragraph, i) => (
                  <p key={i} className="text-gray-300">
                    {paragraph}
                  </p>
                ))}
                {section.list && (
                  <ul className="ml-6 list-disc space-y-2 text-gray-300">
                    {section.list.map((item, i) => (
                      <li key={i}>{item}</li>
                    ))}
                  </ul>
                )}
                {section.note && (
                  <p className="text-gray-300">{section.note}</p>
                )}
              </section>
            ))}

            <div className="flex items-center justify-center">
              <Button
                onClick={() => navigate("/")}
                className="flex items-center text-purple-400 hover:text-purple-300 mb-6"
              >
                <FaArrowLeft className="mr-2" /> Back to Home
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
