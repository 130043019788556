
import React, { Suspense, useState } from 'react';
import { Button } from "src/components/ui/button";
import { Input } from "src/components/ui/input";
import { axiosInstance } from 'src/lib/axiosInstance';
import { useSearchParams } from 'react-router-dom';
import { Loader } from 'src/components/ui/loader';

const ResetPassword: React.FC = () => {
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token')

  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [confirmPasswordError, setConfirmPasswordError] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    const passwordRegex = /^(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{8,}$/;

    setPasswordError('');
    setConfirmPasswordError('');
    setIsLoading(true);

    let valid = true;

    if (!passwordRegex.test(password)) {
      setPasswordError('Password must be at least 8 characters long, include one uppercase letter, one number, and one special character.');
      valid = false;
    }

    if (password !== confirmPassword) {
      setConfirmPasswordError('Passwords do not match.');
      valid = false;
    }

    if (valid) {
      try {
        const data = {
          password: password,
          confirmPassword: confirmPassword,
          token: token,
        };

        const response = await axiosInstance.post('/reset-password', data);

        if (response.data.status) {
          alert(response.data.message);
          window.location.replace('/login');
        }
      } catch (error) {
        console.error(error);
        alert('An error occurred during resetting password.');
      }
    }

    setIsLoading(false);
  };

  return (
    <Suspense fallback={<div>Loading...</div>}>
      <div className="fixed inset-0 bg-black bg-opacity-70 flex items-center justify-center z-50">
        <div className="bg-gray-900 p-8 rounded-lg shadow-lg max-w-md w-full relative">
          <h2 className="text-3xl font-bold mb-8 text-white text-center">Forgot Password</h2>
          <form onSubmit={handleSubmit} className="space-y-4">
            <Input
              type="password"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
              className="w-full bg-gray-800 text-white border-gray-700"
            />
            {passwordError && <p className="text-red-500 text-sm">{passwordError}</p>}

            <Input
              type="password"
              placeholder="Confirm Password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              required
              className="w-full bg-gray-800 text-white border-gray-700"
            />
            {confirmPasswordError && <p className="text-red-500 text-sm">{confirmPasswordError}</p>}

            <Button
              type="submit"
              className="w-full bg-purple-600 hover:bg-purple-700 text-white py-6"
              disabled={isLoading}
            >
              {isLoading ? (
                <Loader />
              ) : (
                'Reset Password'
              )}
            </Button>
          </form>
        </div>
      </div>
    </Suspense>
  );
};

export default ResetPassword;
