import React from 'react'
import { X } from 'lucide-react'
import { Button } from "src/components/ui/button"

interface InstantPayoutsPopupProps {
  isOpen: boolean;
  onClose: () => void;
}

const InstantPayoutsPopup: React.FC<InstantPayoutsPopupProps> = ({ isOpen, onClose }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-gray-800 p-6 rounded-lg shadow-lg max-w-md w-full relative">
        <button
          onClick={onClose}
          className="absolute top-2 right-2 text-gray-400 hover:text-white"
          aria-label="Close popup"
        >
          <X size={24} />
        </button>
        <h2 className="text-2xl font-bold mb-4 text-white">Earn Credits, Get Tokens</h2>
        <div className="space-y-4">
          <p className="text-gray-300">
            Credits earned on the MetaWorm platform will automatically convert to MetaWorm tokens upon our mainnet launch. To transfer your tokens off the platform, you&apos;ll need to complete a simple AML (Anti-Money Laundering) and KYC (Know Your Customer) verification process.
          </p>
          <p className="text-gray-300">
            This ensures a secure and compliant experience for all our users.
          </p>
          <Button onClick={onClose} className="w-full bg-purple-600 hover:bg-purple-700 text-white">
            Close
          </Button>
        </div>
      </div>
    </div>
  );
};

export default InstantPayoutsPopup;

