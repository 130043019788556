
import { useEffect, useState } from 'react'
import { Button } from "src/components/ui/button"
import { Card, CardContent, CardHeader, CardTitle } from "src/components/ui/card"
import { Coins, ExternalLink } from 'lucide-react'
import { useGlobalData } from 'src/context/GlobalDataContext'
import { axiosInstance } from 'src/lib/axiosInstance'
import { getCookieData } from 'src/lib/cookies'
import { Link, useNavigate } from 'react-router-dom'

export default function DashboardPage() {
  const profileData: any = useGlobalData()
  const navigate = useNavigate()
  const [nftData, setNftData] = useState<any[] | null>(null)
  const [tokenData, setTokenData] = useState<any>()

  const userNFTs = () =>{
    try {      
      
      if(profileData?.nfts){
        setNftData(profileData?.nfts)
      }else{
        setNftData([])
      }
    } catch (error) {
      setNftData([])
      console.log(error);
      
    }
  }

  const getData = async() => {
    try {
      const response = await axiosInstance.get('/profile', {
        headers: { Authorization: `Bearer ${await getCookieData()}` },
      });

      if(response.data.status){
        setTokenData(response.data.userProfile)
      }else{
        setTokenData({})
      }
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    window.scrollTo(0, 0)

    if(!tokenData){
      getData()
    }

    if(nftData === null){
      userNFTs()
    }
  }, [nftData, tokenData])

  return (
    <div className="space-y-6" style={{ paddingTop: '80px' }}>
      <Card className="bg-[#1e2538] border-gray-800 pt-4">
        <CardHeader>
          <CardTitle className="text-2xl text-white">Rewards Summary</CardTitle>
        </CardHeader>
        <CardContent className="space-y-6">
          <div className="space-y-4">
            <h2 className="text-xl text-white font-semibold">NFTs Earned</h2>
            {nftData?.length ? <div className="bg-[#141824] p-4 rounded-lg">
              <div className="grid grid-cols-3 gap-4">
                {nftData?.map((nft, index) => (
                 <div 
                 key={index} 
                 className="relative bg-[#1e2538] rounded-lg p-2 flex items-center justify-center"
                 style={{ width: '100%' }}
               >
                 <img
                   src={nft?.image}
                   alt={`NFT ${nft?.name}`} 
                   className="object-cover rounded"
                 />
               </div>
                ))}
              </div>
            </div> : 
          <span className="text-3xl text-white font-bold">
          {0 }
        </span>
          }
          </div>

          <div className="space-y-4">
            <h2 className="text-xl text-white font-semibold">Show NFT Transaction</h2>
            <div className="bg-[#141824] p-4 rounded-lg flex justify-between items-center">
                {profileData?.userProfile?.NFT_Transaction ? (
                    <div className="bg-gray-700 rounded-lg p-4">
                    <a
                      href={profileData?.userProfile?.NFT_Transaction}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-blue-400 hover:text-blue-300 flex items-center"
                    >
                      View Transaction{" "}
                      <ExternalLink className="ml-1 h-4 w-4" />
                    </a>
                  </div>
                  ): <span className="text-3xl text-white font-bold">
                   NFT transaction data not available
                </span> }
            </div>
          </div>

          <div className="space-y-4">
            <h2 className="text-xl text-white font-semibold">Tokens Earned</h2>
            <div className="bg-[#141824] p-4 rounded-lg flex justify-between items-center">
              <span className="text-3xl text-white font-bold">
                {tokenData?.total_reward_tokens?.toLocaleString()? tokenData?.total_reward_tokens?.toLocaleString() : 0 }
              </span>
              <Coins className="h-8 w-8 text-yellow-500" />
            </div>
          </div>

          <div className="space-y-4">
            <h2 className="text-xl text-white font-semibold">WearableNFTs Earned</h2>
            <div className="bg-[#141824] p-4 rounded-lg flex justify-between items-center">
              <span className="text-3xl text-white font-bold">
                {tokenData?.wearable_NFTs?.earned?.toLocaleString()? tokenData?.wearable_NFTs?.earned?.toLocaleString() : 0 }
              </span>
              <Coins className="h-8 w-8 text-yellow-500" />
            </div>
          </div>

          
            <Button 
              className="w-full bg-blue-600 hover:bg-blue-700 text-white py-6 mt-7 text-lg"
              size="lg"
              onClick={() => navigate("/trivia")}
            >
              Play to Earn Tokens
            </Button>
          
        </CardContent>
      </Card>
    </div>
  )
}

