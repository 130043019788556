import React, { useState, useRef, useEffect } from "react";
import { Button } from "src/components/ui/button";
import { Input } from "src/components/ui/input";
import { X } from "lucide-react";
import { FaGoogle, FaFacebook, FaEthereum } from "react-icons/fa";
import { signMessage } from "src/lib/signature.utils";
import { axiosInstance } from "src/lib/axiosInstance";
import Popup from "./Popup";
import { setCookieData } from "src/lib/cookies";
import { useAddress, useSigner } from "@thirdweb-dev/react";
import ConnectWalletButton from "src/connect-wallet/ConnectWalletButton";
interface LoginPopupProps {
  onClose: () => void;
  onRegister: () => void;
  onForgotPassword: () => void;
}

const LoginPopup: React.FC<LoginPopupProps> = ({
  onClose,
  onRegister,
  onForgotPassword,
}) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [popupMessage, setPopupMessage] = useState<string | null>(null);
  const [showPopup, setShowPopup] = useState(false);

  const user = useRef<any>({});

  const signer = useSigner();
  const address = useAddress();
  const modalRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (modalRef.current) {
      modalRef.current.scrollTop = 0;
    }
  }, []);

  useEffect(() => {
    if (signer) {
      user.current.signer = signer;
    }
  }, [signer]);

  useEffect(() => {
    if (address) {
      user.current.address = address;
    }
  }, [address]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsLoading(true);
    if (!email || !password) {
      setIsLoading(false);
      alert("please add all fields");
      return;
    }

    const data = {
      email: email,
      password: password,
    };

    try {
      const response = await axiosInstance.post(`/user-login`, data);

      if (response.data.status) {
        await setCookieData(response.data.token);
        window.location.reload();
      } else {
        setPopupMessage(response.data.message);
        setShowPopup(true);
        setIsLoading(false);
      }
    } catch (error) {
      console.log(error);
      setPopupMessage((error as any).response.data.message);
      setShowPopup(true);
      setIsLoading(false);
    }
  };

  const googleSSO = async () => {
    window.location.href = `${process.env.REACT_APP_BASE_URL_SERVER}/auth/google`;
  };

  const faceBookSSO = async () => {
    window.location.href = `${process.env.REACT_APP_BASE_URL_SERVER}/auth/facebook`;
  };

  const handleWeb3Login = async () => {
    const currentAddress = user.current.address;
    const currentSigner = user.current.signer;

    if (currentSigner && currentAddress) {
      try {
        const message = await signMessage(currentAddress);

        const sign = await currentSigner?.signMessage(message);

        const data = { address: currentAddress, signature: sign };

        const response = await axiosInstance.post("/auth/wallet", data);

        if (response.data.status) {
          setCookieData(response.data?.token);
          window.location.reload();
        }
      } catch (error) {
        setPopupMessage((error as any).message);
        setShowPopup(true);
      }
    } else {
      setPopupMessage("Please connect your registered wallet");
      setShowPopup(true);
    }
  };
  const closePopup = () => {
    setShowPopup(false);
    setPopupMessage(null);
  };

  return (
    <div
      className="fixed inset-0 bg-black/80 flex items-center justify-center z-[100] p-4"
      style={{ paddingTop: "80px" }}
    >
      <div
        ref={modalRef}
        className="relative w-full max-w-md bg-[#0f1116] rounded-lg shadow-xl border border-gray-700 max-h-[90vh] overflow-y-auto"
      >
        <div className="sticky top-0 bg-gray-800 p-4 rounded-t-lg flex justify-between items-center z-10">
          <h2 className="text-xl font-bold text-white">Login to MetaWorm</h2>
          <button
            onClick={onClose}
            className="text-gray-300 hover:text-white transition-colors focus:outline-none focus:ring-2 focus:ring-gray-400 rounded-full p-1"
            aria-label="Close"
          >
            <X className="h-6 w-6" />
          </button>
        </div>

        <div className="p-6">
          <form onSubmit={handleSubmit} className="space-y-4">
            <div className="space-y-2">
              <Input
                type="email"
                placeholder="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
                className="w-full bg-gray-800/50 border-gray-700 text-white placeholder:text-gray-400 h-12"
              />
            </div>
            <div className="space-y-2">
              <Input
                type="password"
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
                className="w-full bg-gray-800/50 border-gray-700 text-white placeholder:text-gray-400 h-12"
              />
            </div>
            <Button
              type="submit"
              disabled={isLoading}
              className="w-full bg-purple-600 hover:bg-purple-700 text-white h-12 text-base font-semibold"
            >
              {isLoading ? (
                <div className="flex items-center justify-center space-x-2">
                  <div className="loader border-t-2 border-white w-4 h-4 rounded-full animate-spin"></div>
                </div>
              ) : (
                "Login"
              )}
            </Button>
          </form>

          <button
            onClick={onForgotPassword}
            className="w-full text-center text-purple-400 hover:text-purple-300 text-sm mt-6 transition-colors"
          >
            Forgot Password?
          </button>

          <div className="relative my-6">
            <div className="absolute inset-0 flex items-center">
              <div className="w-full border-t border-gray-700"></div>
            </div>
            <div className="relative flex justify-center text-sm">
              <span className="px-4 text-gray-400 bg-[#0f1116]">or</span>
            </div>
          </div>

          <div className="space-y-3">
            <Button
              onClick={() => googleSSO()}
              className="w-full bg-red-600 hover:bg-red-700 text-white h-12 text-base font-semibold"
            >
              <FaGoogle className="mr-2 h-5 w-5" /> Login with Google
            </Button>
            <Button
              onClick={() => faceBookSSO()}
              className="w-full bg-blue-600 hover:bg-blue-700 text-white h-12 text-base font-semibold"
            >
              <FaFacebook className="mr-2 h-5 w-5" /> Login with Facebook
            </Button>
            {!address ? (
              <ConnectWalletButton
                handleClaim={null}
                claim={false}
                login={true}
                signup={false}
                registerWallet={false}
                handleWeb3Login={handleWeb3Login}
                handleRegister={null}
              />
            ) : (
              <Button
                onClick={() => handleWeb3Login()}
                className="w-full bg-orange-500 hover:bg-orange-600 text-white h-12 text-base font-semibold"
              >
                <FaEthereum className="mr-2 h-5 w-5" /> Login with Wallet
              </Button>
            )}
          </div>

          <div className="mt-6 text-center">
            <button
              onClick={onRegister}
              className="text-purple-400 hover:text-purple-300 text-base transition-colors"
            >
              Don't have account? Register!
            </button>
          </div>
        </div>
      </div>
      {showPopup && (
        <Popup
          title="Login Status"
          message={popupMessage || ""}
          onClose={closePopup}
        />
      )}
    </div>
  );
};

export default LoginPopup;
