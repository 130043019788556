export const privacyPolicy = [
  // {
  //   title: "Privacy Policy for MetaWorm.io",
  //   paragraphs: [
  //     "Effective Date: January 01, 2024",
  //     "MetaWorm, Inc. (“we,” “us,” or “our”) is committed to protecting your privacy. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you visit our website MetaWorm.io, including any other media form, media channel, mobile website, or mobile application related or connected thereto (collectively, the “Site”).",
  //     "Please read this Privacy Policy carefully. By using the Site, you agree to the terms of this policy. If you do not agree with the terms, please do not use the Site.",
  //   ],
  // },
  {
    title: "2. How We Use Your Information",
    list: [
      "To create and manage your account.",
      "To provide access to trivia, token rewards, and NFT purchases.",
      "To analyze and improve website performance.",
      "To communicate with you about updates, promotions, or changes to our services.",
      "To ensure security and prevent fraud.",
    ],
  },
  {
    title: "3. Sharing Your Information",
    paragraphs: [
      "We do not sell, trade, or rent your personal information to third parties. However, we may share your data in the following scenarios:",
    ],
    list: [
      "With Service Providers: Third-party vendors assisting in website hosting, analytics, and security.",
      "With Legal Authorities: To comply with legal obligations or protect our rights.",
      "In Business Transfers: If we merge, sell, or transfer our business, your data may be transferred.",
    ],
  },
  {
    title: "4. Security of Your Information",
    paragraphs: [
      "We take reasonable administrative, technical, and physical measures to protect your personal information. However, no method of transmission or storage is completely secure. Use our Site at your own risk.",
    ],
  },
  {
    title: "5. Your Data Rights",
    paragraphs: [
      "Depending on your jurisdiction, you may have the following rights:",
    ],
    list: [
      "Access to your data.",
      "Correction of inaccurate data.",
      "Deletion of your data (right to be forgotten).",
      "Opt-out of marketing communications.",
      "Restrict or object to the processing of your data.",
    ],
    note: "To exercise your rights, contact us at privacy@MetaWorm.io.",
  },
  {
    title: "6. Third-Party Links",
    paragraphs: [
      "Our Site may contain links to third-party websites. We are not responsible for the privacy practices of these websites. We encourage you to review their policies before providing any personal data.",
    ],
  },
  {
    title: "7. Children’s Privacy",
    paragraphs: [
      "MetaWorm.io is not intended for individuals under the age of 18. We do not knowingly collect information from minors. If we become aware of data collected from a minor, we will delete it immediately.",
    ],
  },
  {
    title: "8. Updates to This Policy",
    paragraphs: [
      "We may update this Privacy Policy from time to time. Any changes will be reflected on this page with a new effective date. Continued use of the Site indicates acceptance of the updated policy.",
    ],
  },
  {
    title: "9. Contact Us",
    paragraphs: [
      "If you have questions or concerns about this Privacy Policy, contact us at:",
      "MetaWorm, Inc.",
      "Email: support@MetaWorm.io",
      "Address: 11295 Rothschilds Ct., Dublin, Ca 94568",
    ],
  },
]