
import React, { useEffect, useState, Suspense } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Loader } from 'src/components/ui/loader';
import { axiosInstance } from 'src/lib/axiosInstance';

const VerifyEmailContent = () => {
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token'); 
  const [loading, setLoading] = useState(true);
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');

  useEffect(() => {
    const verifyEmail = async () => {
      if (!token) {
        setError('Token is missing.');
        setLoading(false);
        return;
      }

      try {
        const response = await axiosInstance.post('/verify-email', { verificationToken: token });
        setMessage(response.data.message);

        if (response.data?.status) {
          window.location.replace('/');
        }
      } catch (err) {
        setError((err as any).response?.data?.message || 'An error occurred while verifying your email.');
      } finally {
        setLoading(false);
      }
    };

    verifyEmail();
  }, [token]);

  if (loading) {
    return (
      <div className="flex items-center justify-center h-screen">
        <Loader />
      </div>
    );
  }

  return (
    <div className="flex items-center justify-center h-screen">
      <div className="bg-white text-black p-8 rounded-lg w-3/4 max-w-md text-center">
        {message && <p className="text-green-600">{message}</p>}
        {error && <p className="text-red-600">{error}</p>}
      </div>
    </div>
  );
};

const VerifyEmail = () => (
  <Suspense fallback={<div className="flex items-center justify-center h-screen"><Loader /></div>}>
    <VerifyEmailContent />
  </Suspense>
);

export default VerifyEmail;
