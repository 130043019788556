import { useEffect, useRef, useState } from "react";
import { Button } from "src/components/ui/button";
import { FaArrowLeft } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import { useGlobalData } from "src/context/GlobalDataContext";
import { axiosInstance } from "src/lib/axiosInstance";
import UpdateProfile from "src/components/UpdateProfile";
import Popup from "src/components/Popup";
import { getCookieData } from "src/lib/cookies";
import {
  useAddress,
  useNetworkMismatch,
  useSigner,
  useSwitchChain,
} from "@thirdweb-dev/react";
import { ethers } from "ethers";
import { ThirdwebSDK } from "@thirdweb-dev/sdk";
import { ExternalLink } from "lucide-react";
import { Loader } from "src/components/ui/loader";
import NFTClaimGuide from "src/components/NFTGuidePopup";
import ConnectWalletButton from "src/connect-wallet/ConnectWalletButton";

export default function ClaimNFTPage() {
  const [popupMessage, setPopupMessage] = useState<string | null>(null);
  const [showPopup, setShowPopup] = useState(false);
  const [showUpdateProfile, setShowUpdateProfile] = useState(false);
  const [showConnectButton, setShowConnectButton] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [registeredAddress, setRegisteredAddress] = useState<string | null>(
    null
  );
  const [connectWallet, setConnectWallet] = useState(false);
  const [claimedNFTs, setClaimedNFTs] = useState<number | null>(null);
  const [unclaimedNFT, setUnclaimedNFT] = useState<number | null>(null);
  const [showGuide, setShowGuide] = useState(false);
  const isSwitchNetwork = useNetworkMismatch();
  const switchNetwork = useSwitchChain();
  const toggleGuide = () => setShowGuide(!showGuide);
  const user = useRef<any>({});

  const address = useAddress();
  const signer = useSigner();
  const navigate = useNavigate();

  let sdk: ThirdwebSDK;
  const globalData: any = useGlobalData();

  const contractAddress = process.env.REACT_APP_NFT_CONTRACT_ADDRESS;
  const clientId = process.env.REACT_APP_THIRDWEB_CLIENT_ID;
  const network = process.env.REACT_APP_NETWORK;
  let chain: number;
  if (network === "zksync") {
    chain = 324;
  } else {
    chain = 300;
  }

  useEffect(() => {
    if (signer) {
      user.current.signer = signer;
    }
  }, [signer, isSwitchNetwork]);

  useEffect(() => {
    if (address) {
      user.current.address = address;
    }
  }, [address]);

  const claimedAndUnclaimedNFTs = async () => {
    try {
      if (!network || !contractAddress) {
        return;
      }
      sdk = new ThirdwebSDK(network);

      const contract = await sdk.getContract(contractAddress);

      const claimedNFTs = await contract?.erc721?.totalClaimedSupply();

      const unclaimedNFTs = await contract?.erc721?.totalUnclaimedSupply();

      setUnclaimedNFT(unclaimedNFTs.toNumber());

      const num = claimedNFTs.toNumber();

      setClaimedNFTs(num);
    } catch (error) {
      console.log(error);
    }
  };

  const handleClaim = async () => {
    setIsLoading(true);
    setShowConnectButton(false);
    setShowPopup(false);

    const currentAddress = user.current.address;
    const currentSigner = user.current.signer;

    if (!currentAddress || !currentSigner) {
      setPopupMessage("Connect your wallet first.");
      setShowPopup(true);
      setIsLoading(false);
      setShowConnectButton(true);
      return;
    }

    if (currentAddress?.toUpperCase() != registeredAddress?.toUpperCase()) {
      setPopupMessage(
        `Please switch to registered wallet: ${registeredAddress}`
      );
      setShowPopup(true);
      setIsLoading(false);
      setConnectWallet(true);
      return;
    }

    if (!contractAddress) {
      setPopupMessage("Contract address not provided");
      setShowPopup(true);
      setIsLoading(false);
      return;
    }

    if (isSwitchNetwork) {
      setPopupMessage(
        "Connected to wrong network, please change network to zksync mainnet."
      );
      setShowPopup(true);
      setIsLoading(false);
      return;
    }

    try {
      if (!network || !contractAddress) {
        return;
      }

      if (currentSigner) {
        sdk = ThirdwebSDK.fromSigner(currentSigner, network, {
          clientId: clientId,
        });
      }

      const contract = await sdk.getContract(contractAddress);

      const tx = await contract?.erc721?.claim(ethers.BigNumber.from(1));

      await claimedAndUnclaimedNFTs();

      const data = {
        address: address,
        transactionHash: tx[0].receipt?.transactionHash,
      };

      const response = await axiosInstance.post("/claim-nft", data, {
        headers: { Authorization: `Bearer ${await getCookieData()}` },
      });

      if (response.data.status) {
        setPopupMessage(response.data.message);
        window.location.href = window.location.href;
      } else {
        if (response.data?.missing?.pendingEmailVerification) {
          setPopupMessage(response.data.message);
        } else {
          setPopupMessage(response.data.message || "An error occurred.");
          window.location.reload();
        }
      }

      setShowPopup(true);
    } catch (error) {
      const errorReason = extractErrorReason((error as any).message);
      setPopupMessage(errorReason || (error as any).response.data.message);
      setShowPopup(true);
    } finally {
      setIsLoading(false);
    }
  };

  function extractErrorReason(errorMessage: any) {
    const wrongNetworkMatch = errorMessage.match(
      /Could not fetch bytecode for contract at (0x[0-9a-fA-F]{40}) on chain (\d+), double check that the address and chainId are correct/
    );

    if (wrongNetworkMatch) {
      return "Connected to wrong network, please change network to zksync mainnet.";
    }

    const errorNameMatch = errorMessage.match(/errorName="([^"]+)"/);

    if (errorNameMatch && errorNameMatch[1]) {
      return errorNameMatch[1];
    } else {
      const reasonMatch = errorMessage.match(/Reason:\s*(.*?)(\n|$)/);

      if (reasonMatch && reasonMatch[1]) {
        return reasonMatch[1];
      } else {
        return "An unknown error occurred.";
      }
    }
  }

  const onClose = () => {
    setShowConnectButton(false);
    setConnectWallet(() => false);
    closePopup();
  };

  const closePopup = async () => {
    setShowPopup(false);
    setPopupMessage(null);

    if (isSwitchNetwork) {
      await switchNetwork(chain);
    }

    if (connectWallet) {
      setShowConnectButton(true);
    }
  };
  const closeProfileUpdatePopup = () => setShowUpdateProfile(false);
  const openProfileUpdatePopup = () => setShowUpdateProfile(true);

  useEffect(() => {
    claimedAndUnclaimedNFTs();
    if (globalData && !globalData?.status && globalData?.missing?.email) {
      openProfileUpdatePopup();
    }

    if (address) {
      setShowConnectButton(false);
    }

    if (globalData && globalData?.userProfile?.walletAddress) {
      setRegisteredAddress(globalData?.userProfile?.walletAddress);
    }
  }, []);

  return (
    <div
      className="min-h-screen bg-gray-900 flex flex-col items-center justify-center p-4 sm:p-6"
      style={{ paddingTop: "80px" }}
    >
      <div className="bg-gray-800 p-6 sm:p-8 rounded-lg shadow-lg w-full max-w-2xl text-white relative">
        <Link
          to="/"
          className="absolute top-4 left-4 flex items-center text-gray-400 hover:text-white transition-colors duration-200"
        >
          <FaArrowLeft className="mr-2" />
          <span>Back to Home</span>
        </Link>

        <div className="flex flex-col lg:flex-row items-start gap-6 mb-6 mt-5">
          <div className="flex-shrink-0 w-full lg:w-2/5 flex items-center justify-center">
            <img
              src="https://hebbkx1anhila5yf.public.blob.vercel-storage.com/WhatsApp%20Image%202024-12-07%20at%204.04.52%20PM-NIsj7wK8xlnQ6kLIVOQ9QyDzaAdlyC.jpeg"
              alt="MetaWorm Genesis Pass NFT"
              className="rounded-lg shadow-lg w-full max-w-[300px] h-auto"
            />
          </div>
          <div className="w-full lg:w-3/5 space-y-4">
            <h2 className="text-xl font-semibold">MetaWorm Genesis Pass</h2>
            <p className="text-gray-300">
              The MetaWorm Genesis Pass is a limited NFT granting lifetime
              access to partner metaverse, exclusive events, and unique
              privileges, positioning holders as pioneers in the metaverse's
              future.
            </p>
            {claimedNFTs !== null && unclaimedNFT !== null ? (
              <div className="flex justify-between text-sm bg-gray-700 rounded-lg p-3">
                <div className="flex flex-col items-center">
                  <span className="text-gray-300">Available NFTs</span>
                  <span className="text-2xl font-bold text-green-400">
                    {unclaimedNFT}
                  </span>
                </div>
                <div className="flex flex-col items-center">
                  <span className="text-gray-300">Claimed NFTs</span>
                  <span className="text-2xl font-bold text-purple-400">
                    {claimedNFTs}
                  </span>
                </div>
              </div>
            ) : (
              <div style={{ textAlign: "center" }}>
                <Loader />
              </div>
            )}

            <Button
              onClick={toggleGuide}
              className="w-full bg-gray-600 hover:bg-gray-700 mb-2"
            >
              How to Claim Free NFT
            </Button>

            <div className="space-y-4">
              {globalData?.userProfile?.NFT_claim_status ? (
                <>
                  <p className="text-lg mb-6">You already claimed this NFT!!</p>

                  {globalData?.userProfile?.NFT_Transaction && (
                    <div className="space-y-4">
                      <div className="bg-gray-700 rounded-lg p-4">
                        <a
                          href={globalData?.userProfile?.NFT_Transaction}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="text-blue-400 hover:text-blue-300 flex items-center"
                        >
                          View Transaction{" "}
                          <ExternalLink className="ml-1 h-4 w-4" />
                        </a>
                      </div>
                    </div>
                  )}
                </>
              ) : (
                <Button
                  onClick={() => handleClaim()}
                  className="w-full bg-purple-600 hover:bg-purple-700 text-white"
                  disabled={isLoading}
                >
                  {isLoading ? (
                    <div className="flex items-center justify-center space-x-2">
                      <div className="loader border-t-2 border-white w-4 h-4 rounded-full animate-spin"></div>
                      <span>Claiming...</span>
                    </div>
                  ) : (
                    "Claim Metaworm NFT"
                  )}
                </Button>
              )}
            </div>

            <Button
              onClick={() => navigate("/import-nft")}
              className="w-full bg-orange-500 hover:bg-orange-600 mt-2"
            >
              Import NFT to MetaMask
            </Button>
          </div>
        </div>
        <div className="mt-8">
          <h2 className="text-xl font-semibold mb-2">Why do I need an NFT?</h2>
          <p>
            NFTs are an integral part of the MetaWorm experience. They allow you
            to:
          </p>
          <ul className="list-disc list-inside mt-2 space-y-1">
            <li>Participate in exclusive trivia games</li>
            <li>Earn tokens and rewards</li>
            <li>Access special features in our metaverse</li>
          </ul>
        </div>
      </div>

      {showUpdateProfile && <UpdateProfile onClose={closeProfileUpdatePopup} />}
      {showGuide && <NFTClaimGuide onClose={toggleGuide} />}

      {showPopup && (
        <Popup
          title="Claim Status"
          message={popupMessage || ""}
          onClose={closePopup}
        />
      )}

      {showConnectButton && (
        <div className="fixed inset-0 bg-gray-800 bg-opacity-75 flex items-center justify-center z-50">
          <div
            className="bg-gray-900 text-black p-8 rounded-lg w-3/4 max-w-md"
            style={{ display: "block", textAlign: "center" }}
          >
            <ConnectWalletButton
              login={false}
              signup={false}
              registerWallet={false}
              claim={true}
              handleWeb3Login={null}
              handleRegister={null}
              handleClaim={handleClaim}
            />
            <div className="mt-4 text-center">
              <Button
                onClick={onClose}
                className="w-full bg-purple-600 hover:bg-purple-700 text-white"
              >
                Close
              </Button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
