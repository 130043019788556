import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "src/components/ui/button";
import { Input } from "src/components/ui/input";
import { FaGoogle, FaFacebook, FaEthereum } from "react-icons/fa";
import { axiosInstance } from "src/lib/axiosInstance";
import Cookies from "js-cookie";
import { signature, signMessage } from "src/lib/signature.utils";
import { setCookieData } from "src/lib/cookies";
import { Loader } from "src/components/ui/loader";
import ForgotPasswordPopup from "src/components/ForgotPasswordPopup";
import { X } from "lucide-react";
import { useAddress, useSigner } from "@thirdweb-dev/react";
import Popup from "src/components/Popup";
import ConnectWalletButton from "src/connect-wallet/ConnectWalletButton";
export default function LoginPage() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [showForgotPasswordPopup, setShowForgotPasswordPopup] = useState(false);
  const [popupMessage, setPopupMessage] = useState<string | null>(null);
  const [showPopup, setShowPopup] = useState(false);
  // const [currentSigner, setCurrentSigner] = useState<any>();
  // const [currentAddress, setCurrentAddress] = useState("");
  const user = useRef<any>({});

  const address = useAddress();
  const signer = useSigner();
  const navigate = useNavigate();

  useEffect(() => {
    if (signer) {
      user.current.signer = signer;
    }
  }, [signer]);

  useEffect(() => {
    if (address) {
      user.current.address = address;
    }
  }, [address]);

  const handleWeb2Login = async (e: React.FormEvent) => {
    e.preventDefault();

    if (!email || !password) {
      alert("please add all fields");
      return;
    }
    setIsLoading(true);

    const data = {
      email: email,
      password: password,
    };

    try {
      const response = await axiosInstance.post(`/user-login`, data);

      if (response.data.status) {
        await setCookieData(response.data.token);
        window.location.replace("/");
      } else {
        setPopupMessage(response.data?.message);
        setShowPopup(true);
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      setPopupMessage((error as any).response.data.message);
      setShowPopup(true);
      console.log((error as any).response.data.message);
    }
  };

  const googleSSO = async () => {
    window.location.href = `${process.env.REACT_APP_BASE_URL_SERVER}/auth/google`;
  };

  const faceBookSSO = async () => {
    window.location.href = `${process.env.REACT_APP_BASE_URL_SERVER}/auth/facebook`;
  };

  const handleWeb3Login = async () => {
    const currentAddress = user.current.address;
    const currentSigner = user.current.signer;

    if (currentSigner && currentAddress) {
      try {
        const message = await signMessage(currentAddress);

        const sign = await currentSigner?.signMessage(message);

        const data = { address: currentAddress, signature: sign };

        const response = await axiosInstance.post("/auth/wallet", data);

        if (response.data.status) {
          setCookieData(response.data?.token);
          window.location.reload();
        }
      } catch (error) {
        setPopupMessage((error as any).message);
        setShowPopup(true);
      }
    } else {
      setPopupMessage("Please connect your registered wallet");
      setShowPopup(true);
      // navigate('/wallet-guide')
    }
  };

  const openForgotPasswordPopup = () => {
    setShowForgotPasswordPopup(true);
  };
  const closeForgotPasswordPopup = () => {
    setShowForgotPasswordPopup(false);
    setIsLoading(false);
  };
  const closePopup = () => {
    setShowPopup(false);
    setPopupMessage(null);
  };

  useEffect(() => {
    if (Cookies.get("token")) {
      navigate("/");
    }
  });

  return (
    <div
      className="min-h-screen bg-black/8 flex items-center justify-center px-4 sm:px-6"
      style={{ paddingTop: "80px" }}
    >
      <div className="relative w-full max-w-md bg-[#0f1116]/50 rounded-lg shadow-xl max-h-[90vh] overflow-y-auto border border-gray-700">
        <div className="sticky top-0 bg-gray-800 p-4 rounded-t-lg flex justify-between items-center z-10">
          <h2 className="text-xl font-bold text-white">Login to MetaWorm</h2>
          <button
            onClick={() => navigate("/")}
            className="text-gray-300 hover:text-white transition-colors focus:outline-none focus:ring-2 focus:ring-gray-400 rounded-full p-1"
            aria-label="Close"
          >
            <X className="h-6 w-6" />
          </button>
        </div>
        <div className="p-4">
          <form onSubmit={handleWeb2Login} className="space-y-4">
            <div className="space-y-2">
              <Input
                type="email"
                placeholder="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
                className="w-full bg-gray-800/50 border-gray-700 text-white placeholder:text-gray-400 h-12"
              />
            </div>
            <div className="space-y-2">
              <Input
                type="password"
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
                className="w-full bg-gray-800/50 border-gray-700 text-white placeholder:text-gray-400 h-12"
              />
            </div>
            <Button
              type="submit"
              disabled={isLoading}
              className="w-full bg-purple-600 hover:bg-purple-700 text-white h-12 text-base font-semibold"
            >
              {isLoading ? <Loader /> : "Login"}
            </Button>
          </form>
          <div className="mt-4 text-center">
            <button
              onClick={() => navigate("/register")}
              className="text-purple-400 hover:text-purple-300 text-base transition-colors"
            >
              Don't have account? Register!
            </button>
          </div>

          <button
            onClick={openForgotPasswordPopup}
            className="w-full text-center text-purple-400 hover:text-purple-300 text-sm mt-6 transition-colors"
          >
            Forgot Password?
          </button>

          <div className="mt-4 space-y-4">
            <div className="mt-4 flex items-center">
              <hr className="flex-grow border-gray-600" />
              <span className="px-4 text-gray-400">or</span>
              <hr className="flex-grow border-gray-600" />
            </div>

            <div className="mt-4 space-y-2">
              <Button
                onClick={() => googleSSO()}
                className="w-full bg-red-600 hover:bg-red-700 text-white h-12 text-base font-semibold"
              >
                <FaGoogle className="mr-2" /> Login with Google
              </Button>
              <Button
                onClick={() => faceBookSSO}
                className="w-full bg-blue-600 hover:bg-blue-700 text-white h-12 text-base font-semibold"
              >
                <FaFacebook className="mr-2" /> Login with Facebook
              </Button>
              {!address ? (
                <ConnectWalletButton
                  claim={false}
                  login={true}
                  signup={false}
                  registerWallet={false}
                  handleWeb3Login={handleWeb3Login}
                  handleRegister={null}
                  handleClaim={null}
                />
              ) : (
                <Button
                  onClick={() => handleWeb3Login()}
                  className="w-full bg-orange-500 hover:bg-orange-600 text-white h-12 text-base font-semibold"
                >
                  <FaEthereum className="mr-2 h-5 w-5" /> Login with Wallet
                </Button>
              )}
            </div>
          </div>
        </div>
      </div>
      {showForgotPasswordPopup && (
        <ForgotPasswordPopup onClose={closeForgotPasswordPopup} />
      )}
      {showPopup && (
        <Popup
          title="Login Status"
          message={popupMessage || ""}
          onClose={closePopup}
        />
      )}
    </div>
  );
}
