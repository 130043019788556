
export async function getCookieData(): Promise<string | undefined> {
  const getCookie = (name: string) => {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop()?.split(";").shift();
  };

  const cookieData = getCookie("token");

  return new Promise((resolve) =>
    setTimeout(() => {
      resolve(cookieData);
    }, 1000)
  );
}

export async function setCookieData(token: string): Promise<void> {
  const setCookie = (name: string, value: string, days: number = 1) => {
    const expires = new Date(Date.now() + days * 24 * 60 * 60 * 1000).toUTCString();
    document.cookie = `${name}=${value}; expires=${expires}; path=/`;
  };

  setCookie("token", token);

  return new Promise((resolve) =>
    setTimeout(() => {
      resolve();
    }, 1000)
  );
}


export async function removeCookieData(): Promise<void> {
  const removeCookie = (name: string) => {
    document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
  };

  removeCookie("token");

  return new Promise((resolve) =>
    setTimeout(() => {
      resolve();
    }, 1000)
  );
}
