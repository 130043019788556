import { createContext, ReactNode, useContext } from "react";

const GlobalDataContext = createContext<any>(null);

export function GlobalDataProvider({
  children,
  globalData,
}: {
  children: ReactNode;
  globalData: any;
}) {
  return (
    <GlobalDataContext.Provider value={globalData}>
      {children}
    </GlobalDataContext.Provider>
  );
}

export function useGlobalData() {
  const context = useContext(GlobalDataContext);

  return context;
}
