
import React, { useRef, useEffect, useState } from 'react'

const VideoBackground: React.FC = () => {
  const videoRef = useRef<HTMLVideoElement>(null)
  const [videoError, setVideoError] = useState(false)

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.playbackRate = 0.75 // Slow down the video slightly
    }
  }, [])

  const handleVideoError = () => {
    console.error("Video failed to load. Displaying fallback background.")
    setVideoError(true)
  }

  return (
    <div className="fixed inset-0 w-full h-full overflow-hidden">
      {!videoError ? (
        <video
          ref={videoRef}
          autoPlay
          loop
          muted
          playsInline
          onError={handleVideoError}
          className="absolute top-0 left-0 min-w-full min-h-full w-auto h-auto object-cover"
        >
          <source src="https://hebbkx1anhila5yf.public.blob.vercel-storage.com/Navy%20Blue%20Animated%20Business%20Company%20Presentation%20Video%20(2)-8HyDBl6uwYpNguu4Eh4fwDXJvfxQo4.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      ) : (
        <div className="absolute inset-0 bg-gradient-to-br from-purple-900 to-indigo-900 animate-gradient-x">
          <div className="absolute inset-0 flex items-center justify-center">
            <p className="text-white text-opacity-70 text-sm px-4 text-center">Video background could not be loaded.</p>
          </div>
        </div>
      )}
    </div>
  )
}

export default VideoBackground

