
import React, { useState, useEffect } from 'react'
import { motion } from 'framer-motion'
import { PlayCircle, X, FileText } from 'lucide-react'
import VideoBackground from 'src/components/VideoBackground'
import { Button } from "src/components/ui/button"
import { axiosInstance } from 'src/lib/axiosInstance'

interface Video {
  id: number;
  title: string;
  src: string;
  thumbnail: string;
}

interface Document {
  id: number;
  title: string;
  src: string;
}

const fadeIn = {
  hidden: { opacity: 0, y: 20 },
  visible: { opacity: 1, y: 0 }
}

export default function LibraryContent() {
  const [activeTab, setActiveTab] = useState<'promotional' | 'documents'>('promotional')
  const [selectedVideo, setSelectedVideo] = useState<number | null>(null)
  const [videos, setVideos] = useState<Video[]>([])
  const [documents, setDocuments] = useState<Document[]>([])

  useEffect(() => {
    const fetchVideos = async () => {
      const response = await axiosInstance.get('/api/videos')
      const data = response.data
      
      setVideos(data)
    }

    const fetchDocuments = async () => {
      const response = await axiosInstance.get('/api/documents')
      const data = response.data
      setDocuments(data)
    }

    fetchVideos()
    fetchDocuments()
  }, [])

  const closeVideo = () => {
    setSelectedVideo(null)
  }

  return (
    <div className="relative min-h-screen overflow-hidden">
      <VideoBackground />
      <div className="absolute inset-0 bg-black bg-opacity-50 z-10"></div>
      <div className="relative z-20">
        <motion.div 
          className="container mx-auto px-4 py-8 pt-24"
          initial="hidden"
          animate="visible"
          variants={{
            visible: { transition: { staggerChildren: 0.1 } }
          }}
        >
          <motion.h1 
            className="text-6xl font-bold text-center mb-16 bg-gradient-to-r from-pink-500 to-purple-500 text-transparent bg-clip-text"
            variants={fadeIn}
          >
            MetaWorm Library
          </motion.h1>
          
          <motion.div 
            className="flex justify-center mb-12"
            variants={fadeIn}
          >
            <div className="inline-flex rounded-lg bg-gray-900/50 p-1">
              <button
                onClick={() => setActiveTab('promotional')}
                className={`px-6 py-2 rounded-lg text-sm font-medium transition-colors ${
                  activeTab === 'promotional'
                    ? 'bg-purple-600 text-white'
                    : 'text-gray-400 hover:text-white'
                }`}
              >
                Promotional Content
              </button>
              <button
                onClick={() => setActiveTab('documents')}
                className={`px-6 py-2 rounded-lg text-sm font-medium transition-colors ${
                  activeTab === 'documents'
                    ? 'bg-purple-600 text-white'
                    : 'text-gray-400 hover:text-white'
                }`}
              >
                Documents Hub
              </button>
            </div>
          </motion.div>

          {activeTab === 'promotional' && (
            <motion.div 
              className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6"
              variants={fadeIn}
            >
              {videos.map((video) => (
                <motion.div
                  key={video.id}
                  className="relative group rounded-lg overflow-hidden bg-gray-900/60 backdrop-blur-sm"
                  whileHover={{ scale: 1.02 }}
                  transition={{ duration: 0.2 }}
                >
                  <div className="aspect-video relative">
                    <img
                      src={video.thumbnail}
                      alt={video.title}
                      className="w-full h-full object-cover"
                    />
                    <div className="absolute inset-0 bg-black bg-opacity-40 group-hover:bg-opacity-30 transition-all duration-200" />
                    <button
                      onClick={() => setSelectedVideo(video.id)}
                      className="absolute inset-0 flex items-center justify-center"
                      aria-label={`Play ${video.title}`}
                    >
                      <div className="w-16 h-16 flex items-center justify-center rounded-full bg-white/10 backdrop-blur-sm border border-white/20 group-hover:bg-white/20 transition-all duration-200">
                        <PlayCircle className="w-8 h-8 text-white" />
                      </div>
                    </button>
                  </div>
                  <div className="p-4 bg-gray-900/80">
                    <h3 className="text-lg font-semibold text-white mb-1">{video.title}</h3>
                  </div>
                </motion.div>
              ))}
            </motion.div>
          )}

          {activeTab === 'documents' && (
            <motion.div 
              className="max-w-3xl mx-auto"
              variants={fadeIn}
            >
              <div className="space-y-4">
                {documents.map((doc) => (
                  <motion.div
                    key={doc.id}
                    className="flex items-center justify-between p-4 bg-gray-900/50 backdrop-blur-sm rounded-lg hover:bg-gray-800/50 transition-colors duration-200"
                    whileHover={{ scale: 1.01 }}
                  >
                    <span className="text-white flex items-center">
                      <FileText className="mr-2 h-5 w-5 text-purple-400" />
                      {doc.title}
                    </span>
                    <Button 
                      className="px-4 py-2 text-sm font-medium text-white bg-purple-600 rounded-lg hover:bg-purple-700 transition-colors"
                      onClick={() => window.open(doc.src, '_blank')}
                    >
                      View
                    </Button>
                  </motion.div>
                ))}
              </div>
            </motion.div>
          )}
        </motion.div>
      </div>

      {selectedVideo && (
        <div className="fixed inset-0 z-[100] flex items-center justify-center p-4 bg-black/90">
          <div className="relative w-full max-w-4xl">
            <Button
              onClick={closeVideo}
              className="absolute -top-4 right-4 z-[101] text-white hover:text-gray-300 bg-gray-800 hover:bg-gray-700 rounded-full w-8 h-8 flex items-center justify-center shadow-lg border border-gray-700"
              aria-label="Close video"
            >
              <X className="h-5 w-5" />
            </Button>
            <video
              autoPlay
              controls
              className="w-full rounded-lg"
              src={videos.find(v => v.id === selectedVideo)?.src}
            >
              Your browser does not support the video tag.
            </video>
          </div>
        </div>
      )}
    </div>
  )
}

