import React from 'react'
import { Button } from "src/components/ui/button"
import { X } from 'lucide-react'

interface NFTClaimGuideProps {
  onClose: () => void
}

const NFTClaimGuide: React.FC<NFTClaimGuideProps> = ({ onClose }) => {
  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 p-4">
      <div className="bg-gray-800 rounded-lg shadow-lg w-full max-w-2xl text-white relative overflow-y-auto max-h-[90vh]">
        <div className="sticky top-0 bg-gray-800 p-4 flex justify-between items-center border-b border-gray-700">
          <h2 className="text-2xl font-bold">How to Claim Free NFT 🚀</h2>
          <Button
            onClick={onClose}
            variant="ghost"
            className="text-gray-400 hover:text-white"
          >
            <X className="h-6 w-6" />
          </Button>
        </div>
        
        <div className="p-6 space-y-6">
          <p className="text-yellow-300">
            Before claiming, make sure you have a small amount of Ethereum (ETH) in your wallet for gas fees (around $0.20 per transaction on the zkSync Era network). Follow these simple steps:
          </p>

          <div className="space-y-4">
            <div>
              <h3 className="text-xl font-semibold mb-2">1️⃣ Set Up zkSync Era on MetaMask</h3>
              <ul className="list-disc pl-5 space-y-1">
                <li>Open MetaMask, click the network dropdown, and select "Add Network."</li>
                <li>Enter following details:</li>
                <li>Network Name: zkSync Era</li>
                <li>Chain Id: 324</li>
                <li>PRC URL: https://mainnet.era.zksync.io</li>
                <li>Block Explorer: https://explorer.zksync.io</li>
                <li>Save and switch to zkSync Era.</li>
              </ul>
            </div>

            <div>
              <h3 className="text-xl font-semibold mb-2">2️⃣ Get ETH for Gas Fees</h3>
              <ul className="list-disc pl-5 space-y-1">
                <li>In MetaMask, click "Buy" and choose a provider like MoonPay or Transak.</li>
                <li>Buy a small amount of ETH (e.g., $5) with your card, Venmo, or bank transfer.</li>
                <li>Wait for ETH to show in your wallet.</li>
              </ul>
            </div>

            <div>
              <h3 className="text-xl font-semibold mb-2">3️⃣ Claim Your NFT</h3>
              <ul className="list-disc pl-5 space-y-1">
                <li>Go to the claim page, connect your wallet, and follow the steps.</li>
                <li>Confirm the transaction in MetaMask (gas fees apply).</li>
              </ul>
            </div>
          </div>

          <Button onClick={onClose} className="w-full bg-purple-600 hover:bg-purple-700">
            Got it, let's claim!
          </Button>
        </div>
      </div>
    </div>
  )
}

export default NFTClaimGuide