
import React from 'react'
import { Button } from "src/components/ui/button"

interface PopupProps {
  title: string;
  message: string;
  onClose: () => void;
}

const Popup: React.FC<PopupProps> = ({ title, message, onClose }) => {
  return (
    <div className="fixed inset-0 bg-gray-800 bg-opacity-75 flex items-center justify-center z-50">
      <div className="bg-white text-black p-8 rounded-lg w-3/4 max-w-md">
        <h2 className="text-xl font-semibold mb-4">{title}</h2>
        <p>{message}</p>
        <div className="mt-4 text-center">
          <Button onClick={onClose} className="w-full bg-purple-600 hover:bg-purple-700 text-white">
            Close
          </Button>
        </div>
      </div>
    </div>
  )
}

export default Popup
