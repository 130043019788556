import { useGlobalData } from "src/context/GlobalDataContext";
import { Outlet, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { axiosInstance } from "src/lib/axiosInstance";
import EmailVerificationPopup from "src/components/EmailVerificationPopup";
import { getCookieData } from "src/lib/cookies";
import UpdateProfile from "src/components/UpdateProfile";

const UserOnboarding: React.FC = () => {
  const [showPopup, setShowPopup] = useState(false);
  const [popupMessage, setPopupMessage] = useState<string | null>(null);
  const [showUpdateProfile, setShowUpdateProfile] = useState(false);

  const globalData = useGlobalData();
  const navigate = useNavigate();

  const onSubmit = async () => {
    try {
      const response = await axiosInstance.get("/verify-email", {
        headers: { Authorization: `Bearer ${await getCookieData()}` },
      });

      setPopupMessage(response.data?.message);
      setShowPopup(true);
    } catch (error) {
      setPopupMessage((error as any).response.data.message);
    }
  };

  const onClose = () => setShowPopup(false);

  const closeProfileUpdatePopup = () => {
    setShowUpdateProfile(false);
  };

  useEffect(() => {
    if (globalData && !globalData.status) {
      const { missing } = globalData;

      if (missing?.walletAddress) {
        navigate("/wallet-guide");
      } else if (missing?.email) {
        setShowUpdateProfile(true);
      } else if (missing?.pendingEmailVerification) {
        setPopupMessage("Please verify your email to proceed...")
        setShowPopup(true);
      }
    }
  }, [globalData, navigate]);

  return (
    <>
      {showUpdateProfile && (
        <UpdateProfile onClose={closeProfileUpdatePopup} />
      )}
      {showPopup && (
        <EmailVerificationPopup
          title="Verify Email"
          message={popupMessage || ""}
          onSubmit={onSubmit}
          onClose={onClose}
        />
      )}
      {!showUpdateProfile && !showPopup && <Outlet />}
    </>
  );
};

export default UserOnboarding;
