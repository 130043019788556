
import { axiosInstance } from 'src/lib/axiosInstance'
import { setCookieData } from 'src/lib/cookies';
import { useEffect, useState } from 'react'

const FacebookCallback = () => {
  const [authCode, setAuthCode] = useState<string | null>(null)

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const params = new URLSearchParams(window.location.search)
      const code = params.get('code')

      if (code) {
        setAuthCode(code)
        authenticateWithFacebook(code)
      }
    }
  }, [])

  const authenticateWithFacebook = async (code: string) => {
    try {
      const response = await axiosInstance.get(`/auth/facebook/callback?code=${code}`, {
        headers: {
          'Content-Type': 'application/json',
        },
      })

      const data = response.data

      if (data?.status ) {
        await setCookieData(response.data.token)
        window.location.replace("/")
      }
    } catch (error) {
      console.log('Error during authentication:', error)
    }
  }

  if (!authCode) {
    return <div>Loading...</div>
  }

  return (
    <div>
      <p>Authenticating with Facebook...</p>
    </div>
  )
}

export default FacebookCallback
