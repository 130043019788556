'use client'

import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { motion } from 'framer-motion'
import { Button } from "src/components/ui/button"
import { Card, CardContent, CardHeader, CardTitle } from "src/components/ui/card"
import Header from 'src/components/Header'
import VideoBackground from 'src/components/VideoBackground'
import { ArrowLeft, Link, Zap, Coins, Award } from 'lucide-react'

export const dynamic = 'force-dynamic';

const fadeIn = {
  hidden: { opacity: 0, y: 20 },
  visible: { opacity: 1, y: 0 }
}

export default function AboutUsPage() {
    const navigate = useNavigate()
  
    useEffect(() => {
    window.scrollTo(0, 0)
    }, [])

  return (
    <div className="relative min-h-screen bg-gray-900 text-white">
      <VideoBackground />
      <div className="absolute inset-0 bg-black bg-opacity-50 z-10"></div>
      <div className="relative z-20">
        <Header />
        <main className="container mx-auto px-4 py-8 pt-24">
          <motion.div
            initial="hidden"
            animate="visible"
            variants={{
              visible: { transition: { staggerChildren: 0.1 } }
            }}
          >
            <motion.div className="mb-8" variants={fadeIn}>
              <Button
                onClick={() => navigate('/')}
                variant="ghost"
                className="text-purple-400 hover:text-purple-300 flex items-center"
              >
                <ArrowLeft className="mr-2 h-4 w-4" /> Back to Home
              </Button>
            </motion.div>
            <motion.h1
              className="text-4xl sm:text-5xl font-bold mb-8 text-center bg-gradient-to-r from-purple-400 to-pink-500 text-transparent bg-clip-text"
              variants={fadeIn}
            >
              MetaWorm: Revolutionizing the Metaverse Experience
            </motion.h1>
            <motion.div className="max-w-4xl mx-auto space-y-8" variants={fadeIn}>
              <p className="text-lg text-center">
                MetaWorm is an innovative platform designed to unify the fragmented metaverse ecosystem, building a suite of interconnected portals that seamlessly connect different metaverse venues.
              </p>

              <h2 className="text-3xl font-bold mt-12 mb-6 text-center">Core Features & Focus Areas</h2>
              
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                <Card className="bg-gray-800 border-gray-700">
                  <CardHeader>
                    <CardTitle className="flex items-center">
                      <Link className="mr-2 h-6 w-6 text-purple-400" />
                      Interconnected Portals
                    </CardTitle>
                  </CardHeader>
                  <CardContent>
                    <p>A robust infrastructure that bridges diverse metaverse environments, providing users with seamless transitions between virtual worlds while maintaining unified digital identities and assets.</p>
                  </CardContent>
                </Card>

                <Card className="bg-gray-800 border-gray-700">
                  <CardHeader>
                    <CardTitle className="flex items-center">
                      <Zap className="mr-2 h-6 w-6 text-purple-400" />
                      Quantum Beacon Technology
                    </CardTitle>
                  </CardHeader>
                  <CardContent>
                    <p>Proprietary beacons drive foot traffic to real-world and metaverse venues. Businesses and creators can use this tech to attract users through incentivized interactions, promotions, and rewards.</p>
                  </CardContent>
                </Card>

                <Card className="bg-gray-800 border-gray-700">
                  <CardHeader>
                    <CardTitle className="flex items-center">
                      <Coins className="mr-2 h-6 w-6 text-purple-400" />
                      Digital Twin NFTs
                    </CardTitle>
                  </CardHeader>
                  <CardContent>
                    <p>MetaWorm enables brands to create NFTs that act as digital twins of real-world products. When users purchase the NFT, they receive both the digital version and the real-world item, making the metaverse a powerful marketplace for tangible goods.</p>
                  </CardContent>
                </Card>

                <Card className="bg-gray-800 border-gray-700">
                  <CardHeader>
                    <CardTitle className="flex items-center">
                      <Award className="mr-2 h-6 w-6 text-purple-400" />
                      Proof of Action (POA) Mining
                    </CardTitle>
                  </CardHeader>
                  <CardContent>
                    <p>Proof of Action (POA) mining rewards users with tokens for completing verifiable actions, promoting engagement and transparency. It aligns incentives with contributions, enhancing value for platforms like MetaWorm by bridging metaverse and real-world interactions.</p>
                  </CardContent>
                </Card>
              </div>

              <h2 className="text-3xl font-bold mt-12 mb-6">Three-Token Ecosystem</h2>
              <ul className="list-disc list-inside space-y-2">
                <li><strong>ERC-20 (DARA):</strong> A utility token for transactions on the platform.</li>
                <li><strong>ERC-1155 (Vendor Redemption Token):</strong> Ensures value stability for business transactions.</li>
                <li><strong>ERC-721 (Value Minted Token):</strong> Creates deflationary pressure by removing ERC-20 tokens from circulation during minting.</li>
              </ul>

              <h2 className="text-3xl font-bold mt-12 mb-6">Technical Infrastructure</h2>
              <p className="text-lg">
                MetaWorm leverages zkSync Era, a Layer 2 blockchain on Ethereum, ensuring scalability, speed, and cost-efficiency. This blockchain foundation supports our multi-token system, NFT functionality, and Proof of Action mechanics, delivering a seamless and secure experience.
              </p>

              <h2 className="text-3xl font-bold mt-12 mb-6">What We&apos;re Not</h2>
              <p className="text-lg">
                MetaWorm is not a trivia company. Trivia is a means to demonstrate the power of Proof of Action mining, engaging users while laying the groundwork for our larger vision of interoperability, commerce, and innovation within the metaverse.
              </p>
            </motion.div>
          </motion.div>
        </main>
      </div>
    </div>
  )
}

