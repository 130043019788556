import React from 'react'
import { X } from 'lucide-react'
import { Button } from "src/components/ui/button"
import { FaDiscord, FaLinkedin, FaTelegram, FaTwitter } from 'react-icons/fa'

interface CommunityDrivenPopupProps {
  isOpen: boolean;
  onClose: () => void;
}

const CommunityDrivenPopup: React.FC<CommunityDrivenPopupProps> = ({ isOpen, onClose }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-gray-800 p-6 rounded-lg shadow-lg max-w-md w-full relative">
        <button
          onClick={onClose}
          className="absolute top-2 right-2 text-gray-400 hover:text-white"
          aria-label="Close popup"
        >
          <X size={24} />
        </button>
        <h2 className="text-2xl font-bold mb-4 text-white">Join Our Community</h2>
        <div className="space-y-4">
          <Button 
            onClick={() => window.open('https://discord.com/invite/6t87ukrZmy', '_blank')}
            className="w-full bg-indigo-600 hover:bg-indigo-700 text-white flex items-center justify-center"
          >
            <FaDiscord className="mr-2" size={24} />
            Join Discord
          </Button>
          <Button 
            onClick={() => window.open('https://t.me/+XSa0ZZ9K438wOTg5', '_blank')}
            className="w-full bg-blue-500 hover:bg-blue-600 text-white flex items-center justify-center"
          >
            <FaTelegram className="mr-2" size={24} />
            Join Telegram
          </Button>
          <Button 
            onClick={() => window.open('https://www.linkedin.com/company/metaworm/', '_blank')}
            className="w-full bg-blue-500 hover:bg-blue-600 text-white flex items-center justify-center"
          >
            <FaLinkedin className="mr-2" size={24} />
            Join LinkedIn
          </Button>
          <Button 
            onClick={() => window.open('https://x.com/themetaworm?s=21&t=HjUu1b4Yigrkahtse0IHMA', '_blank')}
            className="w-full bg-blue-500 hover:bg-blue-600 text-white flex items-center justify-center"
          >
            <FaTwitter className="mr-2" size={24} />
            Join Twitter
          </Button>
          <Button onClick={onClose} className="w-full bg-gray-600 hover:bg-gray-700 text-white">
            Close
          </Button>
        </div>
      </div>
    </div>
  );
};

export default CommunityDrivenPopup;

