
import { Link } from 'react-router-dom'
import { motion } from 'framer-motion'
import { Button } from "src/components/ui/button"
import { Home, AlertCircle } from 'lucide-react'
import VideoBackground from 'src/components/VideoBackground'
import Header from 'src/components/Header'

export default function NotFound() {
  return (
    <div className="relative min-h-screen bg-gray-900 overflow-hidden">
      <div className="fixed inset-0">
        <VideoBackground />
      </div>
      <div className="absolute inset-0 bg-black bg-opacity-60" />
      <div className="relative z-10">
        <Header />
        {/* Added pt-20 to account for header height */}
        <div className="flex flex-col items-center justify-center min-h-screen px-4 sm:px-6 pt-20">
          <motion.div
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
            className="text-center max-w-2xl mx-auto"
          >
            {/* Increased z-index and adjusted margin */}
            <motion.div 
              className="relative z-20 mb-12 inline-block"
              animate={{ 
                scale: [1, 1.1, 1],
                rotate: [0, 5, -5, 0]
              }}
              transition={{ 
                duration: 3,
                repeat: Infinity,
                repeatType: "reverse"
              }}
            >
              <AlertCircle className="w-24 h-24 text-purple-400 mx-auto" />
            </motion.div>
            <motion.h1 
              className="text-7xl md:text-8xl font-bold mb-4 bg-gradient-to-r from-purple-400 via-pink-500 to-red-500 text-transparent bg-clip-text"
              animate={{ 
                backgroundPosition: ['0% 50%', '100% 50%', '0% 50%'],
              }}
              transition={{ 
                duration: 5,
                repeat: Infinity,
                ease: 'linear'
              }}
              style={{
                backgroundSize: '200% auto',
              }}
            >
              404
            </motion.h1>
            <h2 className="text-2xl md:text-3xl font-semibold mb-6 text-white">
              Reality Distortion Detected
            </h2>
            <div className="bg-gray-800/50 backdrop-blur-sm p-6 rounded-lg mb-8 mx-4">
              <p className="text-lg md:text-xl mb-4 text-gray-300">
                Oops! You&apos;ve stumbled into an unexplored dimension of the MetaWorm universe.
              </p>
              <p className="text-gray-400">
                Our quantum navigators indicate this sector hasn&apos;t been mapped yet.
                Let&apos;s get you back to familiar coordinates.
              </p>
            </div>
            <motion.div
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              className="mb-12"
            >
              <Link to="/">
                <Button 
                  variant="default" 
                  size="lg"
                  className="bg-purple-600 hover:bg-purple-700 text-white font-bold py-6 px-8 rounded-full transition duration-300 ease-in-out transform hover:shadow-neon-purple"
                >
                  <Home className="mr-2 h-5 w-5" />
                  Return to MetaWorm Home
                </Button>
              </Link>
            </motion.div>
          </motion.div>
        </div>        
      </div>
    </div>
  )
}

