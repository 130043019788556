import { ethers } from 'ethers'
import { axiosInstance } from './axiosInstance'

export const signMessage = async (account: string | null) =>{
    try {
        if(!account){
            return 
        }
        const response = await axiosInstance.get(`/sign-message?account=${account}`)
        const signMessage = response.data.message
        return signMessage
    } catch (error) {
        throw error
    }
}

export const signature = async (message: string)=>{
    try {
        if (window?.ethereum === undefined) {
            alert('Please install MetaMask or another Ethereum provider.');
            return;
          }
          const provider = new ethers.providers.Web3Provider(window?.ethereum);
          const signer = provider.getSigner();

          const signature = await signer.signMessage(message)

          return signature

    } catch (error) {
        throw error
    }
}
